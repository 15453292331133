export default [
    {
        title:"Materiales y colores",
        desc:"Una amplia gama de acabos para darle a tu cocina la personalidad que tú elijas.",
        link:"http://publications.eu.roca.com/42322/1047431/#page=23",
        slides: [
            {
                tag: "Cristal orgánico",
                image: "./slider1_image1.jpg"
            },
            {
                tag: "Lacado",
                image: "./slider1_image2.jpg"
            },
            {
                tag: "Lacado canteado",
                image: "./slider1_image3.jpg"
            },
            {
                tag: "Laminado estructurado",
                image: "./slider1_image4.jpg"
            },
            {
                tag: "Laminado liso",
                image: "./slider1_image5.jpg"
            },
        ]
    },
    {
        title:"Sistemas de apertura",
        desc:"¿Con tirador o sin tirador? Tú decides cómo abrir y cerrar las puertas de tu cocina.",
        link:"http://publications.eu.roca.com/42322/1047431/#page=29",
        slides: [
            {
                tag: "Tirador",
                image: "./slider2_image1.jpg"
            },
            {
                tag: "Gola",
                image: "./slider2_image2.jpg"
            },
            {
                tag: "Tiradores integrados",
                image: "./slider2_image3.jpg"
            },
        ]
    },
    {
        title:"Encimeras laminadas de alta presión",
        desc:"Elemento clave y superficie de trabajo. Pon la guinda a la cocina de tus sueños.",
        link:"http://publications.eu.roca.com/42322/1047431/#page=30",
        slides: [
            {
                tag: "Lisas",
                image: "./slider3_image1.jpg"
            },
            {
                tag: "Estructuradas",
                image: "./slider3_image2.jpg"
            },
            {
                tag: "Madera",
                image: "./slider3_image3.jpg"
            },
        ]
    }
]