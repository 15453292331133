import React, { useRef, useContext, useEffect } from 'react'
import styled from 'styled-components'
import logoRounded from '../../images/logo-roca-rounded.png'
import {MapContext} from './mapContext'

const MarkerContainer = styled.div`
  background-color: white;
  padding: 30px 20px 20px 20px;
  width: 140px;
  display: block;
  margin-left: -85px;

  span {
    display:block;
  }
`

const MarkerSwitch = styled.div`
  width: 30px;
  height: 30px;
  position: absolute;
  top: -20px;
  left: -20px;
  background-image: url(${logoRounded});
  background-size: cover;
  background-position: center;
  cursor: pointer;
`

const Marker = ({ markerIndex }) => {

  const { mapInfoItems, setMapInfoItems } = useContext(MapContext)
  const refContainer = useRef(null);
  
  //al hacer click en el Maker se actualiza el objeto de datos de MapContext para mostrar u ocultar el tooltip con la información
  const handleClick = () => {

    //copio en itemUpdated el objeto actual y se hace switch del valor open
    const itemUpdated = {...mapInfoItems[markerIndex], open:!mapInfoItems[markerIndex].open}
    let arr = mapInfoItems

    //recorro el array y seteo a false todos los valores open de todos los objetos
    arr = arr.map((item) => {
      return { ...item, open:false};
    });

    //sustituyo el elemento actual del array por itemUpdated
    arr.splice(markerIndex, 1, itemUpdated)

    //actualizo el objeto de context
    setMapInfoItems(arr)
  }

  //cada vez que se actualiza mapInfoItems se establece a 2000 o a 0 el zindex del elemento padre necesario para que siempre quede por arriba del resto de elementos si está "open" o no
  useEffect(() => {
    refContainer.current.parentNode.parentNode.style.zIndex = mapInfoItems[markerIndex].open ? 2000 : 0 ;
  },[mapInfoItems, markerIndex])

  const MarkerWrapper = styled.div`
    z-index: ${ mapInfoItems[markerIndex].open ? 100 : 0 };
  `

  return(
    <MarkerWrapper>
      <MarkerSwitch ref={refContainer} onClick={handleClick}/>
      { mapInfoItems[markerIndex].open && <MarkerContainer>
        <span><strong>{mapInfoItems[markerIndex].firma}</strong></span>
        <span>{mapInfoItems[markerIndex].direccion}</span>
        <span>{mapInfoItems[markerIndex].direccion1}</span>
        <span>{mapInfoItems[markerIndex].cp}</span>
        <span>{mapInfoItems[markerIndex].provincia}</span>
        <span>{mapInfoItems[markerIndex].horario}</span>
        <span>{mapInfoItems[markerIndex].horario_sab}</span>
      </MarkerContainer>}
    </MarkerWrapper>
  )
}

export default Marker;