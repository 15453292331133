import React, {useEffect} from "react"
import styled from "styled-components"
import {TweenMax, Power3} from 'gsap'

import breakpoints from "../../styles/breakpoints"

import arrowRectangle from "../../images/arrow-slider-rectangle.svg"


const SliderSectionSlideImageContainer = styled.div`
    display: flex;
    align-items: center;
    overflow: hidden;
    position: relative;
    
    width: 100%;

    order: 0;

    max-height: 400px;


    img {
        max-width: 100%;
    }

    @media ${breakpoints.phone} {
        ${({sliderId}) => sliderId % 2 === 0 ? "order: 1;" : "order: 0;"  }
        width: 50%;
        max-height: 500px;
        align-items: inherit;

        img {
            object-fit: cover;
        }
    }
    

`

const ServiceSliderSectionSlideImageNavSwitchLeft = styled.div`
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 0;
    
    margin-top: -30px;
    
    user-select: none;
    z-index: 1;

    border-radius: 50%;
    box-shadow: 0px 2px 19px 0px rgba(0,0,0, .2);
    transition: .4s;

    &:hover {
        opacity: .8;
    }
`

const ServiceSliderSectionSlideImageNavSwitchRight = styled.div`
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 0;

    margin-top: -30px;

    user-select: none;
    z-index: 1;

    transform: rotate(180deg);

    border-radius: 50%;
    box-shadow: 0px 2px 19px 0px rgba(0,0,0, .2);
    transition: .4s;

    &:hover {
        opacity: .8;
    }
`


const SliderSectionSlideImage = ({content, sliderId, currentSlidePosition, next, previous}) => {

    useEffect( () => {

        /* Determine what slide is visible */

        /* sliderId is the id of the parent slider */
        const sliderSectionSlideImage = document.getElementsByClassName('slide' + sliderId)

        TweenMax.to(sliderSectionSlideImage, 1.4, {
            autoAlpha: 1,
            x: currentSlidePosition * 100 + '%',
            ease: Power3.easeInOut,
        })

    }, [currentSlidePosition, sliderId] )

    return (

        <SliderSectionSlideImageContainer sliderId={sliderId}>

                {content.slides.map( (item, index) => <img key={"SliderSectionSlideImageContainerImg" + index} className={"slide" + sliderId} alt={"slider" + sliderId} src={item.image} /> )}

                {currentSlidePosition < 0 && <ServiceSliderSectionSlideImageNavSwitchLeft onClick={() => previous()}>
                    <img src={arrowRectangle} alt="Previous" />
                </ServiceSliderSectionSlideImageNavSwitchLeft>}

                {currentSlidePosition > 1 - content.slides.length && <ServiceSliderSectionSlideImageNavSwitchRight onClick={() => next()}>
                    <img src={arrowRectangle} alt="Next" />
                </ServiceSliderSectionSlideImageNavSwitchRight>}
        
        </SliderSectionSlideImageContainer>
     )
}
 
export default SliderSectionSlideImage