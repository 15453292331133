export default [
  {
    coordenadas: {
      lat: 41.3920128,
      lng: 2.1327076,
    },
    id: 1,
    firma: "Expo Baño Barcelona",
    direccion: "Av. Sarrià, 131-133",
    cp: "08017 Barcelona",
    localidad: "Barcelona",
    provincia: "Barcelona",
    pais: "España",
    horario: "De lunes a viernes: 9:30h - 13:30h y 16:30h-20:30h",
    horario_sab: "Sábados: 9:30h - 13:30h",
    open: false,
    current: false,
    listVisible: false,
  },
  {
    coordenadas: {
      lat: 41.3752068,
      lng: 2.1506143,
    },
    id: 2,
    firma: "General de Llar i Ambient",
    direccion: "Av. Paral·lel, 192",
    cp: "08015 Barcelona",
    localidad: "Barcelona",
    provincia: "Barcelona",
    pais: "España",
    horario: "De lunes a viernes: 9:00h-13:30h y 15:30h-20:00h",
    horario_sab: "Sábados: cerrado",
    open: false,
    current: false,
    listVisible: false,
  },
  {
    coordenadas: {
      lat: 41.3945031,
      lng: 2.1774674,
    },
    id: 3,
    firma: "CSI Suministros",
    direccion: "Calle Ausiàs March, 83",
    cp: "08013 Barcelona",
    localidad: "Barcelona",
    provincia: "Barcelona",
    pais: "España",
    horario: "De lunes a viernes: 9:00h - 13:30h y 16:00h - 19:00h",
    horario_sab: "Sábados: 9:00h - 13:00h",
    open: false,
    current: false,
    listVisible: false,
  },
  /*{
    coordenadas: {
      lat: 41.4029171,
      lng: 1.9780891,
    },
    id: 4,
    firma: "Namu Studi",
    direccion: "Calle del Ponent, 44",
    cp: "08756 La Palma de Cervelló",
    localidad: "La Palma de Cervelló",
    provincia: "Barcelona",
    pais: "España",
    horario: "De lunes a viernes: 9:00h - 17:00h",
    horario_sab: "Sábados: cerrado",
    open: false,
    current: false,
    listVisible: false,
  },*/
  {
    coordenadas: {
      lat: 39.4548469,
      lng: -6.3688076,
    },
    id: 5,
    firma: "Bricon",
    direccion: "Carretera de Miajadas, km 2",
    cp: "10005 Cáceres",
    localidad: "Cáceres",
    provincia: "Cáceres",
    pais: "España",
    horario: "De lunes a viernes: 9:00h - 14:00h y 16:00h - 20:00h",
    horario_sab: "Sábados: 9:00h - 14:00h",
    open: false,
    current: false,
    listVisible: false,
  },
  {
    coordenadas: {
      lat: 41.9617815,
      lng: 2.8056051,
    },
    id: 6,
    firma: "Mafonsa",
    direccion: "Calle de Santa Coloma, 82",
    cp: "17500 Girona",
    localidad: "Girona",
    provincia: "Girona",
    pais: "España",
    horario: "De lunes a viernes: 9:30h-13:30h y 16:00h-19:00h",
    horario_sab: "Sábados: cerrado",
    open: false,
    current: false,
    listVisible: false,
  },
  {
    coordenadas: {
      lat: 39.5747643,
      lng: 2.6738674,
    },
    id: 7,
    firma: "Antonio Cabot Fornés",
    direccion: "Calle Sant Leandre, 74",
    cp: "07008 Palma de Mallorca",
    localidad: "Palma de Mallorca",
    provincia: "Islas Baleares",
    pais: "España",
    horario: "De lunes a viernes: 9:00h - 13:30h y 16:00h - 19:00h",
    horario_sab: "Sábados: cerrado",
    open: false,
    current: false,
    listVisible: false,
  },
  {
    coordenadas: {
      lat: 39.8873648,
      lng: 4.241641,
    },
    id: 8,
    firma: "Landino",
    direccion: "Av. Cap de Cavalleria, 22",
    cp: "07714 Maó",
    localidad: "Maó",
    provincia: "Islas Baleares",
    pais: "España",
    horario: "De lunes a viernes: 9:00h a 13:30h y 16:00h a 19:30h.",
    horario_sab: "Sábados: 9:00h a 19:30h.",
    open: false,
    current: false,
    listVisible: false,
  },
  {
    coordenadas: {
      lat: 40.3444682,
      lng: -3.8518613,
    },
    id: 9,
    firma: "Rodrisan",
    direccion: "Calle Bruselas, 4",
    cp: "28922 Alcorcón",
    localidad: "Alcorcón",
    provincia: "Madrid",
    pais: "España",
    horario: "De lunes a viernes: 10:00h-14:00h y 16:00h-20:30h",
    horario_sab: "Sábados: 10:00h-14:00h y 17:00h-21:00h",
    open: false,
    current: false,
    listVisible: false,
  },
  {
    coordenadas: {
      lat: 40.02076,
      lng: -3.6091987,
    },
    id: 10,
    firma: "Sanehogar",
    direccion: "Calle de Gonzalo Chacón, 53",
    cp: "28300 Aranjuez",
    localidad: "Aranjuez",
    provincia: "Madrid",
    pais: "España",
    horario: "De lunes a viernes: 9:00h - 13:30h y 16:00h - 19:00h",
    horario_sab: "Sábados: 9:00h - 13:00h",
    open: false,
    current: false,
    listVisible: false,
  },
  {
    coordenadas: {
      lat: 40.4424295,
      lng: -3.7049699,
    },
    id: 11,
    firma: "Laguardia & Moreira",
    direccion: "Calle de Rios Rosas, 9",
    cp: "28003 Madrid",
    localidad: "Madrid",
    provincia: "Madrid",
    pais: "España",
    horario: "De lunes a viernes: 9:30h-13:30h y 16:00h-20:00h",
    horario_sab: "Sábados: 10:00h-13:30h",
    open: false,
    current: false,
    listVisible: false,
  },
  {
    coordenadas: {
      lat: 40.582432,
      lng: -3.996331,
    },
    id: 12,
    firma: "Ceycesa",
    direccion: "Camino de Navatornera, 8",
    cp: "28260 Galapagar",
    localidad: "Galapagar",
    provincia: "Madrid",
    pais: "España",
    horario: "De lunes a viernes: 9:30h-13:30h y 16:30h-19:00h",
    horario_sab: "Sábados: 7:30h-14:30h",
    open: false,
    current: false,
    listVisible: false,
  },
  {
    coordenadas: {
      lat: 40.292314,
      lng: -4.002497,
    },
    id: 13,
    firma: "Ceycesa",
    direccion: "Camino de Villaviciosa",
    cp: "28600 Navalcarnero",
    localidad: "Navalcarnero",
    provincia: "Madrid",
    pais: "España",
    horario: "De lunes a viernes: 9:30h-13:30h y 16:30h-19:00h",
    horario_sab: "Sábados: 9:30h-13:30h",
    open: false,
    current: false,
    listVisible: false,
  },
  {
    coordenadas: {
      lat: 40.400801,
      lng: -3.69429,
    },
    id: 14,
    firma: "Ceycesa",
    direccion: "Calle del Ferrocarril, 3",
    cp: "28045 Madrid",
    localidad: "Madrid",
    provincia: "Madrid",
    pais: "España",
    horario: "De lunes a viernes: 9:30h-13:30h y 16:30h-19:00h",
    horario_sab: "Sábados: 9:30h-13:30h",
    open: false,
    current: false,
    listVisible: false,
  },
  {
    coordenadas: {
      lat: 42.2152743,
      lng: -8.7434909,
    },
    id: 15,
    firma: "Laguardia & Moreira",
    direccion: "Av. Da Florida, 62",
    cp: "36210 Vigo",
    localidad: "Vigo",
    provincia: "Pontevedra",
    pais: "España",
    horario: "De lunes a viernes: 9:30h-13:30h y 16:00h-20:30h",
    horario_sab: "Sábados: 10:00h-13:30h",
    open: false,
    current: false,
    listVisible: false,
  },
  {
    coordenadas: {
      lat: 41.776314,
      lng: -2.486903,
    },
    id: 16,
    firma: "Alejandro del Amo - Adasa",
    direccion: "Polígono Industrial Las Casas",
    direccion1: "Calle C, 225",
    cp: "42005 Soria",
    localidad: "Soria",
    provincia: "Soria",
    pais: "España",
    horario: "De lunes a viernes: 9:30h-13:30h y 16:00h-19:00h",
    horario_sab: "Sábados: cerrado",
    open: false,
    current: false,
    listVisible: false,
  },
  {
    coordenadas: {
      lat: 39.4691484,
      lng: -0.4023014,
    },
    id: 17,
    firma: "Saneamiento y Suministros",
    direccion: "Av. Del Cid, 78",
    cp: "46018 Valencia",
    localidad: "Valencia",
    provincia: "Valencia",
    pais: "España",
    horario: "De lunes a viernes: 9:00h - 14:00h y 16:00h - 20:00h",
    horario_sab: "Sábados: 9:00h - 13:00h",
    open: false,
    current: false,
    listVisible: false,
  },
  {
    coordenadas: {
      lat: 43.302579,
      lng: -2.946501,
    },
    id: 18,
    firma: "Saneamientos DEK",
    direccion: "Carretera Bilbao Plentzia, 40",
    cp: "48950 Erandio",
    localidad: "Erandio",
    provincia: "Vizcaya",
    pais: "España",
    horario: "De lunes a viernes: 9:30h-13:30h y 16:00h-19:00h",
    horario_sab: "Sábados: cerrado",
    open: false,
    current: false,
    listVisible: false,
  },
  {
    coordenadas: {
      lat: "37.2224913",
      lng: "-3.6324076",
    },
    id: 19,
    firma: "Histamar SL",
    direccion: "Pol. Ind. La Noria",
    cp: "18800 Baza",
    localidad: "Baza",
    provincia: "Granada",
    pais: "españa",
    horario: "De lunes a viernes: 9:00-14:00 y 16:00 a 20:00",
    horario_sab: "Sábados: 9:00 - 14:00",
    open: false,
    current: false,
    listVisible: false,
  },
  /*{
    coordenadas: {
      lat: "41.4068792",
      lng: "2.1803261",
    },
    id: 20,
    firma: "Don Bany",
    direccion: "Calle Valencia, 550",
    cp: "08013 Barcelona",
    localidad: "Barcelona",
    provincia: "Barcelona",
    pais: "españa",
    horario: "De lunes a viernes: 9:30-13:30 y 17:00 a 20:30",
    horario_sab: "Sábados: 10:00 - 14:00",
    open: false,
    current: false,
    listVisible: false,
  },*/
  {
    coordenadas: {
      lat: "28.4596231",
      lng: "-16.257346",
    },
    id: 21,
    firma: "Afelsa",
    direccion: "Av. Tres de Mayo, 18",
    cp: "38005 Santa Cruz de Tenerife",
    localidad: "Santa Cruz de Tenerife",
    provincia: "Tenerife",
    pais: "españa",
    horario: "De lunes a viernes: 9:30-13:30 y 16:30 a 20:30",
    horario_sab: "Sábados: 10:00 - 13:30",
    open: false,
    current: false,
    listVisible: false,
  },
  {
    coordenadas: {
      lat: "42.595546",
      lng: "-5.5718079",
    },
    id: 22,
    firma: "Sanyclima",
    direccion: "Calle la Rúa, 47",
    cp: "24003 León",
    localidad: "León",
    provincia: "León",
    pais: "españa",
    horario: "De lunes a viernes: 10:00-14:00 y 16:00 a 20:00",
    horario_sab: "Sábados: 10:00 - 14:00",
    open: false,
    current: false,
    listVisible: false,
  },
  {
    coordenadas: {
      lat: "39.9712604",
      lng: "-0.0749036",
    },
    id: 23,
    firma: "Taymon",
    direccion: "Av. de Lairón, nave 35, 36",
    cp: "12006 Castellón de la Palma",
    localidad: "Castellón de la Plana",
    provincia: "Castellón",
    pais: "españa",
    horario: "De lunes a viernes: 9:00-13:30 y 16:00 a 19:00",
    horario_sab: "Sábados: cerrado",
    open: false,
    current: false,
    listVisible: false,
  },
  {
    coordenadas: {
      lat: "28.130479",
      lng: "-15.4358627",
    },
    id: 24,
    firma: "Cegrisa",
    direccion: "Calle Alfredo Calderón, 61",
    cp: "35006 Las Palmas de Gran Canaria",
    localidad: "Las Palmas de Gran Canaria",
    provincia: "Las Palmas",
    pais: "españa",
    horario: "De lunes a viernes: 9:30-13:00 y 16:00 a 20:00",
    horario_sab: "Sábados: 9:00 - 13:00",
    open: false,
    current: false,
    listVisible: false,
  },
  {
    coordenadas: {
      lat: "27.9663826",
      lng: "-15.3927578",
    },
    id: 25,
    firma: "Cegrisa",
    direccion: "Urb. Industrial El Goro, Calle Los Peregrinos, 1",
    cp: "35219 Telde",
    localidad: "Telde",
    provincia: "Las Palmas",
    pais: "españa",
    horario: "De lunes a viernes: 9:30-13:00 y 16:00 a 20:00",
    horario_sab: "Sábados: 9:00 - 13:00",
    open: false,
    current: false,
    listVisible: false,
  },
  {
    coordenadas: {
      lat: "41.5344889",
      lng: "2.0983451",
    },
    id: 26,
    firma: "Masia",
    direccion: "Calle de Raimon Casellas, 30",
    cp: "08205 Sabadell",
    localidad: "Sabadell",
    provincia: "Barcelona",
    pais: "españa",
    horario: "De lunes a viernes: 9:00-13:30 y 16:00 a 19:00",
    horario_sab: "Sábados: cerrado",
    open: false,
    current: false,
    listVisible: false,
  },
  {
    coordenadas: {
      lat: "41.3378504",
      lng: "1.6886053",
    },
    id: 27,
    firma: "Sia Biosca",
    direccion: "Calle de Cubelles, 7",
    cp: "08720 Vilafranca del Penedés",
    localidad: "Vilafranca del Penedés",
    provincia: "Barcelona",
    pais: "españa",
    horario: "De lunes a viernes: 9:00-13:00 y 15:00 a 19:00",
    horario_sab: "Sábados: 9:00 - 13:00",
    open: false,
    current: false,
    listVisible: false,
  },
  {
    coordenadas: {
      lat: "35.28428",
      lng: "-2.9431787",
    },
    id: 28,
    firma: "Juan Montes Hoyo",
    direccion: "Calle Comandante García Morato, 9",
    cp: "52006 Melilla",
    localidad: "Melila",
    provincia: "Melilla",
    pais: "españa",
    horario: "De lunes a viernes: 10:00-13:00 y 17:00 a 20:00",
    horario_sab: "Sábados: 10:00 - 13:00",
    open: false,
    current: false,
    listVisible: false,
  },
  {
    coordenadas: {
      lat: "41.5393258",
      lng: "1.8930589",
    },
    id: 29,
    firma: "Refornet SL",
    direccion: "Calle Mossèn Jacint Verdaguer, 36",
    cp: "08640 Olesa de Montserrat",
    localidad: "Olesa de Montserrat",
    provincia: "Barcelona",
    pais: "españa",
    horario: "De lunes a viernes: 9:30-13:30 y 16:00 a 19:00",
    horario_sab: "Sábados: cerrado",
    open: false,
    current: false,
    listVisible: false,
  },
  {
    coordenadas: {
      lat: "43.3745479",
      lng: -"5.8120221",
    },
    id: 30,
    firma: "Comercial Sariego",
    direccion: "Pol. Ind. Espíritu Santo, Pol Espíritu Santo, 30",
    cp: "33010 Oviedo",
    localidad: "Oviedo",
    provincia: "Asturias",
    pais: "españa",
    horario: "De lunes a viernes: 10:00-13:30 y 16:00 a 20:00",
    horario_sab: "Sábados: cerrado",
    open: false,
    current: false,
    listVisible: false,
  },
  {
    coordenadas: {
      lat: "41.6111477",
      lng: -"4.7100058",
    },
    id: 31,
    firma: "Kaferma",
    direccion: "Calle Plomo, 6",
    cp: "47012 Valladolid",
    localidad: "Valladolid",
    provincia: "Valladolid",
    pais: "españa",
    horario: "De lunes a viernes: 9:00-13:30 y 15:30 a 19:00",
    horario_sab: "Sábados: cerrado",
    open: false,
    current: false,
    listVisible: false,
  },
  {
    coordenadas: {
      lat: "41.1342426",
      lng: "1.1157101",
    },
    id: 32,
    firma: "Guerin",
    direccion: "Calle d'Astorga, 83",
    cp: "43205 Reus",
    localidad: "Reus",
    provincia: "Tarragona",
    pais: "españa",
    horario: "De lunes a viernes: 9:00-13:00 y 15:00 a 18:00",
    horario_sab: "Sábados: cerrado",
    open: false,
    current: false,
    listVisible: false,
  },
  {
    coordenadas: {
      lat: "41.4030149",
      lng: "2.1630548",
    },
    id: 33,
    firma: "Works Barcelona",
    direccion: "Paseo De Sant Joan, 170",
    cp: "08037 Barcelona",
    localidad: "Barcelona",
    provincia: "Barcelona",
    pais: "españa",
    horario: "De lunes a viernes: 9:00-14:00 y 15:00 a 19:00",
    horario_sab: "Sábados: cerrado",
    open: false,
    current: false,
    listVisible: false,
  },
  {
    coordenadas: {
      lat: "40.97147328661347",
      lng: "-5.657498563698866",
    },
    id: 34,
    firma: "Talentto",
    direccion: "Calle Maria Auxiliadora, Av. de Portugal, 36",
    cp: "37004 Salamanca",
    localidad: "Salamanca",
    provincia: "Salamanca",
    pais: "españa",
    horario: "De lunes a viernes: 10:00h a 14:00h y 17:00h a 20:30h",
    horario_sab: "Sábados: 10:00h a 14:00h",
    open: false,
    current: false,
    listVisible: false,
  },
  {
    coordenadas: {
      lat: "39.48677737998491",
      lng: "-1.0987856289782643",
    },
    id: 35,
    firma: "Ceramics Home Requena",
    direccion: "Calle Carmen, 6",
    cp: "46340 Requena",
    localidad: "Requena",
    provincia: "Valencia",
    pais: "españa",
    horario: "De lunes a viernes: de 8:30h a 14:00h y 16:00h a 19:00h",
    horario_sab: "Sábados: cerrado",
    open: false,
    current: false,
    listVisible: false,
  },
  {
    coordenadas: {
      lat: "39.46938008449126",
      lng: "-0.37187019794957793",
    },
    id: 36,
    firma: "El Corte Inglés S.A.",
    direccion: "Carrer de Colón, 27",
    cp: "46004 Valencia",
    localidad: "Valencia",
    provincia: "Valencia",
    pais: "españa",
    horario: "De lunes a viernes: 10:00h a 22:00h",
    horario_sab: "Sábados: 10:00h a 22:00h",
    open: false,
    current: false,
    listVisible: false,
  },
  {
    coordenadas: {
      lat: "39.150790975553676",
      lng: "-0.4413495953361584",
    },
    id: 37,
    firma: "Jose Carpena Diseño",
    direccion: "Av. Luis Suñer, 19",
    cp: "46600 Alzira",
    localidad: "Alzira",
    provincia: "Valencia",
    pais: "españa",
    horario: "De lunes a viernes: 9:00h a 13:30h y 17:00h a 21:00h",
    horario_sab: "Sábados: 9:00h a 13:30h",
    open: false,
    current: false,
    listVisible: false,
  },
  {
    coordenadas: {
      lat: "36.78359041811673",
      lng: "-2.607733940647639",
    },
    id: 38,
    firma: "Saneamientos Emilio Hernandez S.A.",
    direccion: "Carretera Alicum , 150",
    cp: "04740 Roquetas de Mar",
    localidad: "Roquetas de Mar",
    provincia: "Almería",
    pais: "españa",
    horario: "De lunes a viernes: 10:00h a 13:30h y 17:00h a 20:30h",
    horario_sab: "Sábados: 10:00h a 13:30h",
    open: false,
    current: false,
    listVisible: false,
  },
  {
    coordenadas: {
      lat: "38.727039411511086",
      lng: "0.0722133595874107",
    },
    id: 39,
    firma: "J.P.M. Dal-Ros S.L.",
    direccion: "Polígono Industrial La Pedrera - Calle Lumiere, 3",
    cp: "03720 Benissa",
    localidad: "Benissa",
    provincia: "Alicante",
    pais: "españa",
    horario: "De lunes a viernes: 8:00h a 13:00h y 15:00h a 19:00h",
    horario_sab: "Sábado: cerrado",
    open: false,
    current: false,
    listVisible: false,
  },
  {
    coordenadas: {
      lat: "38.402515152060104",
      lng: "-0.42736107300206366",
    },
    id: 40,
    firma: "Eurocasa Suministros",
    direccion: "Avenida Miguel Hernández, 62",
    cp: "03550 San Juan",
    localidad: "San Juan",
    provincia: "Alicante",
    pais: "españa",
    horario: "De lunes a viernes: 10:00h a 20:30h",
    horario_sab: "Sábados: 10:00h a 13:30h y 17:30h a 20:30h",
    open: false,
    current: false,
    listVisible: false,
  },
  {
    coordenadas: {
      lat: "38.97096652284973",
      lng: "1.3097676297648349",
    },
    id: 41,
    firma: "Antonio Cabot Fornes, S.A.",
    direccion: "Calle Doctor Fleming, 38",
    cp: "07820 San Antoni de Portmany",
    localidad: "San Antoni de Portmany",
    provincia: "Islas Baleares",
    pais: "españa",
    horario: "De lunes a viernes: 8:00h a 13:00h y 15:00h a 19:00h",
    horario_sab: "Sábados: cerrado",
    open: false,
    current: false,
    listVisible: false,
  },
  {
    coordenadas: {
      lat: "37.130802798736816",
      lng: "-3.5843951137792907",
    },
    id: 42,
    firma: "Histamar, S.L.",
    direccion: "Calle Adolfo Pérez Esquivel, S/N Polígono industrial El Laurel (Junto al Centro Comercial Alhsur)",
    cp: "18140 La Zubia",
    localidad: "La Zubia",
    provincia: "Granada",
    pais: "españa",
    horario: "De lunes a viernes: 09:00h a 14:00h y 16:00h a 20:00h",
    horario_sab: "Sábados: 9:00h a 14:00h",
    open: false,
    current: false,
    listVisible: false,
  },
  {
    coordenadas: {
      lat: "42.192422237349",
      lng: "2.505343770383135",
    },
    id: 43,
    firma: "Calygas S.L.",
    direccion: "Calle Pep Ventura, S/N",
    cp: "17800 Olot",
    localidad: "Olot",
    provincia: "Girona",
    pais: "españa",
    horario: "De lunes a viernes: 9:00h a 13:00h y 15:00h a 19:00h",
    horario_sab: "Sábados: cerrado",
    open: false,
    current: false,
    listVisible: false,
  },
  {
    coordenadas: {
      lat: "41.67635199008795",
      lng: "2.7775099693946066",
    },
    id: 44,
    firma: "Mafonsa Blanes",
    direccion: "Carretera de la Estació, 56",
    cp: "17300 Blanes",
    localidad: "Blanes",
    provincia: "Girona",
    pais: "españa",
    horario: "De lunes a viernes: 7:30h a 19:00h",
    horario_sab: "Sábados: 8:00h a 13:00h",
    open: false,
    current: false,
    listVisible: false,
  },
  {
    coordenadas: {
      lat: "41.54914105398305",
      lng: "2.0389033924239053",
    },
    id: 45,
    firma: "Comercial Peralba",
    direccion: "Av. del Vallès 123",
    cp: "08223 Terrassa",
    localidad: "Terrassa",
    provincia: "Barcelona",
    pais: "españa",
    horario: "De lunes a viernes: 7:30h a 13:30h y 15:00h a 19:00h",
    horario_sab: "Sábados: 09:00h a 13:00h",
    open: false,
    current: false,
    listVisible: false,
  },
  {
    coordenadas: {
      lat: "41.487512381647456",
      lng: "1.9153633364684195",
    },
    id: 46,
    firma: "Refornet SL",
    direccion: "Carrer Montserrat, 158",
    cp: "08760 Martorell",
    localidad: "Martorell",
    provincia: "Barcelona",
    pais: "españa",
    horario: "De lunes a viernes: 9:30h -13:00h y 15:30h a 18:00h",
    horario_sab: "Sábados: cerrado",
    open: false,
    current: false,
    listVisible: false,
  },
]
