import React from "react"
import Wrapper from "../grid/wrapper"
import styled from "styled-components"

const VideoContainer = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; /* 16/9 ratio */
  padding-top: 30px; /* IE6 workaround*/
  margin-top: 80px;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`
const VideoSection = () => {
  return (
    <Wrapper style={{ marginTop: "6rem" }}>
      <VideoContainer>
        <iframe
          src="https://www.youtube.com/embed/rIboVYgEwYU"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </VideoContainer>
    </Wrapper>
  )
}

export default VideoSection
