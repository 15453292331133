import React from "react"
import styled from "styled-components"

import fonts from "../../fonts"
import breakpoints from "../../styles/breakpoints"

import Wrapper from "../grid/wrapper"
import Row from "../grid/row"
import Column from "../grid/column"

import ExperiencesCta from "./experiencesCta"
import ScrollAnimation from "react-animate-on-scroll"


const Anchor = styled.div`
  display: block;
  position: relative;
  top: -100px;
  visibility: hidden;
`

const ExperiencesTitle = styled.h2`
    font-size: 33px;
    line-height: 37px;
    font-family: ${fonts.helvetica};
    font-weight: bold;
    text-align: center;

    margin: 0 auto 40px;

    span {
        font-family: ${fonts.life};
        font-weight: normal;
    }

    @media ${breakpoints.phone} {
        font-size: 40px;
        line-height: 45px;
    }
`

const ExperiencesDesc = styled.div`
    font-size: 16px;
    font-family: ${fonts.helvetica};
    text-align: center;
    letter-spacing: -0.5px;
    line-height: 30px;
    display: flex;
    justify-content: center;

    margin: 0 auto 75px;
`

const Experiences = () => (
        <section>
            <Anchor id="porque"/>
            <Wrapper>
                <Row>

                    <ScrollAnimation animateIn="fadeIn">
                        <Column xs={12}>

                            <ExperiencesTitle>
                                Experiencia, diseño y calidad, <br/>
                                <span>garantía de una buena cocina</span>
                            </ExperiencesTitle>

                        </Column>
                    </ScrollAnimation>

                    <ExperiencesDesc>
                            <Column xs={10}>
                                <ScrollAnimation animateIn="fadeIn">
                                    Reformar la zona más importante de la casa no es una decisión sencilla. Por eso se hace todavía más importante poder contar con una marca de confianza, con una marca que lleva toda una vida a nuestro lado.
                                </ScrollAnimation>
                            </Column>
                        </ExperiencesDesc>

                </Row>

                <Row>
                    
                    <Column xs={12} sm={6} md={4}>
                        <ScrollAnimation animateIn="fadeIn" animateOnce>
                            <ExperiencesCta number="1" title="Soluciones integrales para todas las necesidades" text="Sea cual sea el espacio disponible, Roca tiene una solución, porque el mobiliario siempre se adapta a las diferentes necesidades y espacios." image="./soluciones.png" />
                        </ScrollAnimation>
                    </Column>

                    <Column xs={12} sm={6} md={4}>
                        <ScrollAnimation animateIn="fadeIn" animateOnce delay={100}>
                            <ExperiencesCta number="2" title="Gran variedad de acabados, colores y diseños" text="En Roca hacemos realidad todas las posibilidades. Desde lacados o laminados de máxima calidad a maderas naturales, que se pueden elegir por separado o combinarlas." image="./acabados.png" />
                        </ScrollAnimation>
                    </Column>

                    <Column xs={12} sm={6} md={4}>
                        <ScrollAnimation animateIn="fadeIn" animateOnce delay={200}>
                            <ExperiencesCta number="3" title="Muebles de máxima calidad" text="El sistema de fabricación único de los cascos de los muebles de cocina Roca garantizan máxima estabilidad y rigidez, año tras año." image="./muebles.png" />
                        </ScrollAnimation>
                    </Column>

                    <Column xs={12} sm={6} md={4}>
                        <ScrollAnimation animateIn="fadeIn" animateOnce>
                            <ExperiencesCta number="4" title="Bisagras y herrajes de larga vida garantizada" text="Para poder garantizar una larga vida solo trabajamos con las mejores calidades, con amortiguaciones suaves y silenciosas." image="./bisagras.png" />
                        </ScrollAnimation>
                    </Column>

                    <Column xs={12} sm={6} md={4}>
                        <ScrollAnimation animateIn="fadeIn" animateOnce delay={100}>
                            <ExperiencesCta number="5" title="Garantía Roca 10 años" text="Roca ofrece una garantía de 10 años en los muebles y las puertas, para que la cocina siga tan perfecta como el primer día." image="./garantia.png" />
                        </ScrollAnimation>
                    </Column>

                    <Column xs={12} sm={6} md={4}>
                        <ScrollAnimation animateIn="fadeIn" animateOnce delay={200}>
                            <ExperiencesCta number="6" title="Tiendas Roca es atención, servicio y asesoramiento" text="Los vendedores profesionales de Roca son los encargados de conseguir materializar la cocina con la que siempre habías soñado." image="./tiendas.png" />
                        </ScrollAnimation>
                    </Column>

                </Row>

            </Wrapper>
        </section>
);

export default Experiences
