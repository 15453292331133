import React from 'react'
import styled from 'styled-components'

import fonts from "../../fonts"
import breakpoints from "../../styles/breakpoints"

//data:
import sliderCocinasMedida from '../../data/sliderCocinasMedida';

//component:
import SliderSection from "./sliderSection"

import Wrapper from "../grid/wrapper"
import Row from "../grid/row"
import Column from "../grid/column"

import ScrollAnimation from "react-animate-on-scroll"

const Section = styled.section`
    width: 100%;
`

const Anchor = styled.div`
  display: block;
  position: relative;
  top: -100px;
  visibility: hidden;
`

const CocinasMedidasContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    max-width: 100%;
`

const EstilosTitle = styled.h2`
    font-size: 33px;
    line-height: 37px;
    font-family: ${fonts.helvetica};
    font-weight: bold;
    text-align: center;

    margin: 0 auto 40px;

    span {
        font-family: ${fonts.life};
        font-weight: normal;
    }

    @media ${breakpoints.phone} {
        font-size: 40px;
        line-height: 45px;
    }
`

const DescriptionContainer = styled.p`
    font-size: 20px;
    font-family: ${fonts.helvetica};
    text-align: center;
    letter-spacing: -0.5px;
    line-height: 30px;
    display: flex;
    justify-content: center;
    margin: 0 auto 25px;
`



const CocinasMedidas = () => (
    <Section>
        <Anchor id="personalizacion" />
        <Wrapper>
            <Row>

                <Column xs={12}>
                    <ScrollAnimation animateIn="fadeIn">
                        <EstilosTitle>
                            Cocinas <span>a medida</span>
                        </EstilosTitle>
                    </ScrollAnimation>
                </Column>

                <Column xs={10} align="center">
                    <ScrollAnimation animateIn="fadeIn">
                        <DescriptionContainer>
                            En el mundo no hay dos personas iguales, por eso, Roca ofrece cocinas a medida, únicas, adaptadas a cada estilo y necesidad. Roca se adapta al espacio y no al revés.
                        </DescriptionContainer>
                    </ScrollAnimation>
                </Column>

            </Row>
        </Wrapper>

        <ScrollAnimation animateIn="fadeIn">
            <CocinasMedidasContainer>
                {/* sliderId is th ID of each slider, allows to determine what slides and bullet have to be updated */}
                {sliderCocinasMedida.map( (item, index) => <SliderSection key={"SliderSection" + index} content={item} sliderId={index}/>)}
            </CocinasMedidasContainer>
        </ScrollAnimation>
    </Section>
)

export default CocinasMedidas