import React, { useContext } from 'react'
import styled from 'styled-components'
import colors from '../../styles/colors'
import Column from '../grid/column'
import { MapContext } from './mapContext'

const InfoItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 0;
  font-size: 14px;
  line-height: 21px;
  width: 100%;

  .link {
    color: ${colors.primary};
    width: fit-content;
    transition: all .4s ease;
    
    :hover {
      text-decoration: underline;
      color: ${colors.primaryLight};
    }
  }
`

const InfoItemTitle = styled.h2`
  font-size: 20px;
  font-weight: bold;
  line-height: 30px;
  margin: 0 0 20px;
`

const InfoItem = ({ itemIndex }) => {

  //declaro los objetos de MapContext
  const { mapInfoItems, setMapInfoItems } = useContext(MapContext)
  
  //al hacer click en un enlace "ver mapa" se actualiza el objeto de datos mapInfoItems de MapContext el cual es accesible desde varios componentes
  const handleClick = () => {
    //copio en itemUpdated el objeto actual y se hace switch del valor open y establezco current a true
    const itemUpdated = {...mapInfoItems[itemIndex], open:!mapInfoItems[itemIndex].open, current: true}
    let arr = mapInfoItems
  
    //recorro el array y seteo a false todos los valores open y current a false, en todos los objetos
    arr = arr.map((item) => {
      return { ...item, open:false, current: false};
    });
  
    //sustituyo el elemento actual del array por itemUpdated
    arr.splice(itemIndex, 1, itemUpdated)
  
    //actualizo el objeto de context
    setMapInfoItems(arr)
  }

  return(
  <Column xs={12} sm={6} md={4}>
    <InfoItemContainer>
      <InfoItemTitle>{mapInfoItems[itemIndex].localidad}</InfoItemTitle>
      <span><strong>{mapInfoItems[itemIndex].firma}</strong></span>
      <span>{mapInfoItems[itemIndex].direccion}</span>
      <span>{mapInfoItems[itemIndex].direccion1}</span>
      <span>{mapInfoItems[itemIndex].cp}</span>
      <span>{mapInfoItems[itemIndex].provincia}</span>
      <span>{mapInfoItems[itemIndex].horario}</span>
      <span>{mapInfoItems[itemIndex].horario_sab}</span>
      <a href="#donde" className="link" onClick={handleClick}>Ver mapa</a>
    </InfoItemContainer>
  </Column>
)}

export default InfoItem;