import React, {useState, useEffect} from 'react';
import styled from "styled-components"
import { Link } from "gatsby"
import ScrollAnimation from "react-animate-on-scroll"

import useWindowSize from "../../utils/useWindowSize"

import colors from '../../styles/colors'
import fonts from '../../fonts/index'
import breakpoints from '../../styles/breakpoints'

import logoCocinas from "../../images/logo-cocinas.svg"
import arrowDown from "../../images/arrow_down.svg"
import HeroBackground from "../../images/hero-bg.jpg"

const HeroSection = styled.section`
  background-image: url(${HeroBackground});
  background-size: cover;
  background-position: center;
  text-align: center;
  color: ${colors.tertiary};
  position: relative;
  margin: 0;
  height: 100vh;
  height: ${({height}) => height && height}px;
  width: 100%;
  animation: fadein 2s;

    @keyframes fadein {
      from {opacity:0;}
      to {opacity:1;}
  }
`
const HeroLink = styled(Link)`
  color: ${colors.tertiary};
  font-size: 14px;
  font-weight: bold;
  line-height: 21px;
  position: absolute;
  bottom: 0;
  display:block;
  padding-bottom: 40px;

  ::after {
    content: '';
    position: absolute;
    width: 15px;
    height: 10px;
    left: 36px;
    bottom: 20px;
    background-repeat: no-repeat;
    background-image: url(${arrowDown});
    animation: flip-flop .8s infinite;
  }

  @keyframes flip-flop {
    0%   { transform: translate(0, 0) }
    50% { transform: translate(0, 10px) }
  }

  transition: all .4s ease;
  opacity: 1;

    :hover {
        opacity: .6
    }
`
const HeroTitle = styled.h1`
  font-size: 47px;
  line-height: 53px;
  font-family: ${fonts.helvetica};
  font-weight: bold;
  padding: 0 15px;

  span {
    font-weight: 200;
    font-family: ${fonts.life};
  }

  @media ${breakpoints.tablet}{
    font-size: 69px;
    line-height: 78px;
  }
`

const HeroUpper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
`

const Logo = styled.div`
  display: flex;
  font-size: 40px;
  justify-content: center;

  .roca-logo {
    width: 185px;
  }

  @media ${breakpoints.phone}{
    .roca-logo {
      width: 260px;
    }
  }

  @media ${breakpoints.tablet}{
    .roca-logo {
      width: 320px;
    }
  }
`

const Hero = () => {

  const size = useWindowSize()

  const [height, setHeight] = useState(null)

  useEffect( () => {
    setHeight(size.height)
    // eslint-disable-next-line
  }, [] )

  return (
    <HeroSection id="hero" height={height}>
      <HeroUpper>
        <ScrollAnimation animateIn="fadeIn">
          <HeroTitle>Roca llega donde<br></br><span>se cocinan las emociones</span></HeroTitle>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeIn">
          <Logo>
            <img className="roca-logo" alt="Roca - Logo" src={logoCocinas} />
          </Logo>
        </ScrollAnimation>
        <HeroLink to={'/#sliderSection'}>Descúbrelas</HeroLink>
      </HeroUpper>
    </HeroSection>
  );
}
export default Hero