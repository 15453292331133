import React from 'react'
import styled from "styled-components"

import colors from '../../styles/colors'
import fonts from "../../fonts"

const CocinasCtaContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flext-start;
    justify-content: flex-start;
    justify-self: center;

    min-height: 100%;
    padding: 0 0 40px 0;
    box-sizing: border-box;
`

const CocinasImage = styled.img`
    width: 100%;
    height: 250px;
    object-fit: cover;
    margin: 0 0 20px;
`

const CocinasTitle = styled.h3`
    font-family: ${fonts.helvetica};    
    font-size: 23px;
    font-weight: bold;
    line-height: 26px;

    margin: 20px 0;
`

const CocinasDesc = styled.p`
    font-family: ${fonts.helvetica};
    font-size: 16px;
    line-height: 24px;

    margin: 0 0 20px;
`

const CocinasLink = styled.a`
    width: fit-content;
    font-family: ${fonts.helvetica};
    font-size: 14px;
    font-weight: bold;
    line-height: 21px;
    color: ${colors.tertiaryDark};

    margin: 0 0 20px 0;
    transition: all .4s ease;
    
    :hover {
      text-decoration: underline;
      color: ${colors.primaryLight};
    }
`

const CocinasCta = ({desc, link, title, text, image}) => (

        <CocinasCtaContainer>

            <CocinasImage src={image} alt={title}/>

            <CocinasTitle>
                {title}
            </CocinasTitle>

            <CocinasDesc>
                {desc}
            </CocinasDesc>

            <CocinasLink href={link} target="_blank" rel="noopener noreferrer">
                {text}
            </CocinasLink>

        </CocinasCtaContainer>

     )

export default CocinasCta