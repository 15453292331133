import React, { useState } from "react"
import styled from "styled-components"

import SliderSectionSlideImage from "./sliderSectionSlideImage"
import SliderSectionSlideText from "./sliderSectionSlideText"

const SliderSectionContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: stretch;
    width: 100%;

    flex-wrap: wrap;
`

const SliderSection = ({content, sliderId}) => {

    /* Control slider position */
    const [slidePosition, setSlidePosition] = useState(0)


    /* Manage Next, Previous event  */
    const handleSliderPosition = (i) => {
        setSlidePosition(slidePosition + i)
    }

    /* Manage Click on Bullets */
    const handleChangeBullet = (i) => {
        setSlidePosition(-i)
    }

    return (
        <SliderSectionContainer>
            <SliderSectionSlideText content={content} sliderId={sliderId} currentSlidePosition={slidePosition} bullet={ handleChangeBullet }/>
            <SliderSectionSlideImage content={content} sliderId={sliderId} currentSlidePosition={slidePosition} next={ () => handleSliderPosition(-1) } previous={ () => handleSliderPosition(1) }/>
        </SliderSectionContainer>
    )
}

export default SliderSection