import React from "react"
import styled from "styled-components"
import fonts from "../../fonts"
import colors from "../../styles/colors"
import breakpoints from "../../styles/breakpoints"

import Wrapper from "../grid/wrapper"
import Row from "../grid/row"
import Column from "../grid/column"

//image:
import negocioBackgroundDesktop from "../../images/img-negocio-desktop.png"
import negocioBackgroundTablet from "../../images/img-negocio-tablet.png"
import negocioBackgroundMobile from "../../images/img-negocio-mobile.png"


import ScrollAnimation from "react-animate-on-scroll"

const Anchor = styled.div`
  display: block;
  position: relative;
  top: -100px;
  visibility: hidden;
`

const NegocioContainer = styled.section`
  background-image: url(${negocioBackgroundMobile});
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;

  width: 100%;
  min-height: 376px;
  position: relative;
  margin: 0;
  padding: 20px 0;

  color: ${colors.tertiary};
  font-family: ${fonts.helvetica};
  font-weight: bold;

  @media ${breakpoints.tablet} {
    background-image: url(${negocioBackgroundTablet});
    flex-direction: row;
    min-height: 428px;
    
  }

  @media ${breakpoints.desktop} {
    min-height: 468px;
    background-image: url(${negocioBackgroundDesktop});
    
  }
 
`

const NegocioCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  width: 100%;
  margin-top: 15px;

  @media ${breakpoints.tablet} {
    align-items: flex-start;
    margin: 0;
    text-align: center;
    justify-content: center;
  }
`

const Title = styled.div`
  font-size: 33px;
  line-height: 38px;
  font-weight: normal;
  margin-right: 0;
  margin-bottom: 20px;
  text-align: center;

  @media ${breakpoints.tablet} {
    margin-bottom: 20px;
    margin-right: 0;
    font-size: 48px;
    line-height: 54px;
  }
`

const Text = styled.p`
    font-size: 16px;
    font-family: ${fonts.helvetica};
    text-align: center;
    font-weight: normal;
    letter-spacing: -0.5px;
    line-height: 22px;
    display: flex;
    justify-content: center;
    margin: 0;
    
    .hide-mobile { display: none; }
    
  @media ${breakpoints.tablet} {
    font-size: 20px;
    line-height: 30px;
    
    .hide-mobile { display: block; }
    
  }
`

const NegocioLink = styled.a`
    width: fit-content;
    font-family: ${fonts.helvetica};
    font-size: 16px;
    line-height: 22px;
    font-weight: bold;
    color: ${colors.tertiary};
    text-decoration:none;

    transition: all .4s ease;
    
    :hover {
      text-decoration: none;
      color: ${colors.primaryLight};
    }
    
    @media ${breakpoints.tablet} {
      font-size: 20px;
      line-height: 30px;
    }
`

const Negocio = () => (
  <NegocioContainer>
    <Anchor id="negocio" />
    <Wrapper>
      <Row>
        <Column sm={12} align="center">
          <NegocioCenter>
            <ScrollAnimation animateIn="fadeIn">
              <Title>Tenemos la receta para<br/><strong>impulsar tu negocio</strong></Title>
              <Text>Súmate a nuestra red de distribuidoras y conviértete en <br class={"hide-mobile"}/>uno de los ingredientes de éxito en:</Text>
              <Text>
                <NegocioLink href="mailto:cocinas@roca.net">cocinas@roca.net</NegocioLink>
              </Text>
            </ScrollAnimation>
          </NegocioCenter>
        </Column>
      </Row>
    </Wrapper>
  </NegocioContainer>
)

export default Negocio
