import React from "react"
import styled from "styled-components"

import fonts from "../../fonts"
import colors from "../../styles/colors"

import Wrapper from "../grid/wrapper"
import Row from "../grid/row"
import Column from "../grid/column"

//image:
import logoRoca from "../../images/logo-roca.svg"

//component:
import FooterSocial from "./footerSocial"

const FooterS = styled.footer`
  width: 100%;

  background: ${colors.primary};
  font-size: 12px;
  font-weight: normal;
  line-height: 18px;
  color: ${colors.tertiary};
  font-family: ${fonts.helvetica};

  a {
    text-decoration: none;
    color: ${colors.tertiary};
    transition: all 0.4s ease;

    :hover {
      text-decoration: underline;
      color: ${colors.primaryLight};
    }
  }
`

const FooterLegal = styled.div`
  margin-top: 30px;
`

const Logo = styled.img`
  max-width: 60px;
  height: auto;
`

const Footer = () => {
  return (
    <FooterS>
      <Wrapper>
        <Row>
          <Column sm="6" md="9" mdOffset="1" lgOffset="0">
            <Logo alt="Roca - Logo" src={logoRoca} />
          </Column>

          <Column sm="6" md="3">
            <FooterSocial />
          </Column>

          <Column sm="4" md="9" align="left" mdOffset="1" lgOffset="0">
            <FooterLegal>
              © Copyright 2022 Roca Sanitario S.A.{" "}
              <a
                href="https://www.roca.es/footer/legal/politica-privacidad"
                target="_blank"
                rel="noopener noreferrer"
              >
                Aviso legal y política de cookies.
              </a>
            </FooterLegal>
          </Column>
        </Row>
      </Wrapper>
    </FooterS>
  )
}

export default Footer
