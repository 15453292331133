import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { TweenMax, Power3 } from "gsap"

import { breakpoints } from "../../styles/breakpoints"

import useWindowSize from "../../utils/useWindowSize"

import colors from "../../styles/colors"

const HotspotSliderBulletContainer = styled.div`
  display: flex;
  margin: 0 1px;

  cursor: pointer;

  @media (min-width: ${breakpoints.desktop}px) {
    background: black;
    width: 160px;
    height: inherit;
  }
`

const HotspotSliderBulletImage = styled.img`
  max-width: 100%;
  transition: 0.4s;

  &:hover {
    opacity: 0.6 !important;
    filter: blur(1px) !important;
  }
`

const HotspotSliderBulletSymbol = styled.div`
  width: 10px;
  height: 10px;
  background-color: ${colors.tertiaryDark};
  border-radius: 50%;
  margin: 30px 5px;
  cursor: pointer;
  transition: 0.4s;

  &:hover {
    opacity: 0.6 !important;
    filter: blur(1px) !important;
  }
`

const HotspotSliderBullet = ({
  content,
  handleChangeBullet,
  bulletId,
  currentSlidePosition,
}) => {
  const size = useWindowSize()

  const [width, setWidth] = useState(null)

  useEffect(() => {
    const currentBullet = document.getElementsByClassName(
      "bulletHotspot" + -currentSlidePosition
    )
    const bullet = document.getElementsByClassName("bulletHotspot")

    if (size.width > breakpoints.desktop) {
      TweenMax.to(bullet, 0.4, {
        opacity: 0.2,
        webkitFilter: "blur(3px)",
        ease: Power3.easeInOut,
      })
      TweenMax.to(currentBullet, 0.4, {
        opacity: 1,
        webkitFilter: "blur(0)",
        ease: Power3.easeInOut,
      })
    } else {
      TweenMax.to(bullet, 0.4, {
        opacity: 0.4,
        ease: Power3.easeInOut,
      })
      TweenMax.to(currentBullet, 0.4, {
        opacity: 1,
        ease: Power3.easeInOut,
      })
    }

    setWidth(size.width)
  }, [currentSlidePosition, size])

  return (
    <HotspotSliderBulletContainer currentSlidePosition={currentSlidePosition}>
      {width > breakpoints.desktop && (
        <HotspotSliderBulletImage
          alt="bullet Hotspot"
          className={"bulletHotspot bulletHotspot" + bulletId}
          src={content.image}
          onClick={() => handleChangeBullet(-bulletId)}
        />
      )}
      {width <= breakpoints.desktop && (
        <HotspotSliderBulletSymbol
          className={"bulletHotspot bulletHotspot" + bulletId}
          onClick={() => handleChangeBullet(-bulletId)}
        />
      )}
    </HotspotSliderBulletContainer>
  )
}

export default HotspotSliderBullet
