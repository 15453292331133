import React from "react"
import styled from "styled-components"
import fonts from "../../fonts"
import Wrapper from "../grid/wrapper"
import Row from "../grid/row"
import Column from "../grid/column"
import CocinasCta from "./cocinasCta"
import breakpoints from '../../styles/breakpoints'
import ScrollAnimation from "react-animate-on-scroll"

const CocinasTitle = styled.h2`
    font-size: 33px;
    line-height: 37px;
    font-family: ${fonts.helvetica};
    font-weight: bold;
    text-align: center;

    margin: 60px auto 15px;

    span {
        font-family: ${fonts.life};
        font-weight: normal;
    }

    @media ${breakpoints.phone}{
        margin: 60px auto 40px;
        font-size: 40px;
        line-height: 45px;
    }
`

const CocinasDesc = styled.p`
    font-size: 20px;
    font-family: ${fonts.helvetica};
    text-align: center;
    letter-spacing: -0.5px;
    line-height: 30px;
    display: flex;
    justify-content: center;
    margin: 0 auto 75px;
`


const Cocinas = () => (
    <section>
        <Wrapper>

            <Row>

                <ScrollAnimation animateIn="fadeIn">
                    <Column xs={12}>       
                        <CocinasTitle>
                            Cocinas donde <span>cada elemento importa</span>
                        </CocinasTitle>
                    </Column>
                </ScrollAnimation>

                <Column xs={10} align="center">
                    <ScrollAnimation animateIn="fadeIn">
                        <CocinasDesc>
                            La grifería de cocina y el fregadero son elementos necesarios e indispensables en cada cocina, capaces de realzar cualquier espacio. En Roca somos expertos en ofrecer calidad y diseño desde hace 100 años.
                        </CocinasDesc>
                    </ScrollAnimation>
                </Column>

            </Row>

            <Row>
                    <Column xs={10} md={6} align="center">
                        <ScrollAnimation animateIn="fadeInLeft">
                            <CocinasCta title="Griferías" desc="La grifería de cocina es la más utilizada en el hogar. Es por ello que tiene que ser especialmente robusta a cualquier manipulación del día a día. Roca sabe muy bien que un excelente cartucho, un gran cromado y utilizar el mejor latón es garantía de calidad contrastada." image="./griferias.jpg" link="https://www.roca.es/catalogo/productos/#!/griferia/griferia-cocina" text="Más detalles" />
                        </ScrollAnimation>
                    </Column>

                    <Column xs={10} md={6} align="center">
                        <ScrollAnimation animateIn="fadeInRight">
                            <CocinasCta title="Fregaderos" desc="Pasamos un porcentaje de tiempo muy alto delante del fregadero: llenar cazos, lavar vajilla o simplemente llenarse un vaso de agua. Es imprescindible que sea un espacio funcional y estético. Roca ofrece infinidad de modelos para encontrar la mejor solución que se adapte a tu día a día." image="./fregaderos.jpg" link="https://www.roca.es/catalogo/productos/#!/fregaderos" text="Más detalles" />
                        </ScrollAnimation>
                    </Column>
            </Row>

        </Wrapper>
    </section>
)

export default Cocinas