import React from 'react'
import styled from 'styled-components'

import colors from '../../styles/colors'
import breakpoints from '../../styles/breakpoints'

//images:
// import facebook from '../../images/ico-facebook.svg'
// import twitter from '../../images/ico-twitter.svg'
// import linkedin from '../../images/ico-linkedin.svg'

//icons:
import { SocialIcon } from 'react-social-icons';


const SocialContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 60px 0 0;

  @media ${breakpoints.tablet} {
    margin: 0 0 0 auto;
  }

`

const SocialTitle = styled.div`
  font-size: 16px;
  font-weight: 700;
  line-height: 1.75;

`

const FooterSocialS = styled.ul`
  display: flex;
  padding: 0;


  li {
    list-style: none;
    display: flex;
    margin-right: 20px;
  }

  li:last-child {
    margin-right: 0;
  }

  .social-icon {
    /* background: ${colors.primary}; */
    border: 1px solid ${colors.tertiary};
    border-radius: 100% !important;
    width: 40px !important;
    height: 40px !important;
    transition: all .4s ease;

    &:hover{
      background: ${colors.tertiary};
      .social-svg-icon {
        fill: ${colors.primary} !important;
      }
    }
  }
  
`

// const currentURL = window.location.href; //not accepted for build

const data = {
  //"pageUrl": currentURL,
  "pageUrl": "https://www.roca.es/cocinas",
  "title": "Roca Cuines",
  "text": "Roca llega al lugar donde se cocinan las emociones. Descubre Roca Cocinas y déjate sorprender. https://www.roca.es/cocinas",
}


export default () => (
  <SocialContainer>
    <SocialTitle>Compartir:</SocialTitle>
    <FooterSocialS>
        <li>
            <SocialIcon url={"https://www.facebook.com/sharer.php?u=" + data.pageUrl} target="_blank" rel="noopener noreferrer" network="facebook" bgColor="transparent" fgColor={colors.tertiary}/>
        </li>

        <li>
            <SocialIcon url={ "https://twitter.com/intent/tweet?text=" + data.text + data.pageUrl } target="_blank" rel="noopener noreferrer" network="twitter" bgColor="transparent" fgColor={colors.tertiary}/>
        </li>

        <li>
            <SocialIcon url={"http://www.linkedin.com/shareArticle?mini=true&summary=youtube&title=" + data.title  + "&url=" + data.pageUrl} target="_blank" rel="noopener noreferrer" network="linkedin" bgColor="transparent" fgColor={colors.tertiary}/>
        </li>
    </FooterSocialS>
  </SocialContainer>
);