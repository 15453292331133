import React from "react"
import styled from 'styled-components'

import breakpoints from "../../styles/breakpoints"
import fonts from "../../fonts"

import Wrapper from "../grid/wrapper"
import Column from "../grid/column"
import Row from "../grid/row"

import HotspotSlider from "./hotspotSlider"
import ScrollAnimation from "react-animate-on-scroll"

const EstilosSection = styled.section`
    width: 100%;
`

const Anchor = styled.div`
  display: block;
  position: relative;
  top: -100px;
  visibility: hidden;
`

const HotspotTitle = styled.h2`
    font-size: 33px;
    line-height: 37px;
    font-family: ${fonts.helvetica};
    font-weight: bold;
    text-align: center;

    margin: 0 auto 40px;

    span {
        font-family: ${fonts.life};
        font-weight: normal;
    }

    @media ${breakpoints.phone} {
        font-size: 40px;
        line-height: 45px;
    }
`

const HotspotDescription = styled.p`
    font-size: 20px;
    font-family: ${fonts.helvetica};
    text-align: center;
    letter-spacing: -0.5px;
    line-height: 30px;
    display: flex;
    justify-content: center;
    margin: 0 auto 40px;
`

const Hotspot = () => {
    
    return (
        <EstilosSection>

            <Anchor id="estilos" />

            <Wrapper>

                <Row>

                    <Column xs={12}>

                        <ScrollAnimation animateIn="fadeIn">
                            <HotspotTitle>
                                Cocinas que inspiran<br/><span>tu creatividad</span>
                            </HotspotTitle>
                        </ScrollAnimation>

                    </Column>

                    
                    <Column xs={10} align="center">
                        <ScrollAnimation animateIn="fadeIn">
                            <HotspotDescription>
                                Aquí te proponemos tres estilos como punto de partida para que puedas crear la cocina que mejor se adapte a tu gusto.
                            </HotspotDescription>
                        </ScrollAnimation>
                    </Column>


                </Row>

                <Row>

                    <Column xs={12}>

                        <HotspotSlider/>

                    </Column>
                
                </Row>

            </Wrapper>
        </EstilosSection>
    )
}

export default Hotspot