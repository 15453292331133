import React, { useState, useRef, useEffect } from "react"
import styled from "styled-components"

import useWindowSize from "../../utils/useWindowSize"

import sliderHotspots from "../../data/sliderHotspots"

import arrow from "../../images/arrow-slider.svg"
import arrowRectangle from "../../images/arrow-slider-rectangle.svg"

import { breakpoints } from "../../styles/breakpoints"

import HotspotSlide from "./hotspotSlide"
import HotspotSliderBullet from "./hotspotSliderBullet"

const HotspotSliderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  width: 100%;

  position: relative;

  overflow: visible;

  @media (min-width: ${breakpoints.desktop}px) {
    overflow: hidden;
  }
`

const HotspotSlides = styled.div`
  display: flex;
  width: 100%;

  overflow: hidden;
  box-shadow: 0px 2px 19px 0px rgba(0, 0, 0, 0.2);

  @media (min-width: ${breakpoints.desktop}px) {
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
  }
`

const HotspotSliderNavSwitchLeft = styled.div`
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 0;
  transform: rotate(0);

  margin-top: -30px;

  user-select: none;
  z-index: 1;

  border-radius: 50%;
  box-shadow: 0px 2px 19px 0px rgba(0, 0, 0, 0.2);

  transition: 0.4s;

  &:hover {
    opacity: 0.8;
  }

  @media (min-width: ${breakpoints.phone}px) {
    top: 35%;
  }

  @media (min-width: ${breakpoints.tablet}px) {
    transform: rotate(180deg);
    left: 30px;
    top: 45%;
  }

  @media (min-width: ${breakpoints.desktop}px) {
    top: 50%;
  }
`

const HotspotSliderNavSwitchRight = styled.div`
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 0;
  transform: rotate(180deg);

  margin-top: -30px;

  user-select: none;
  z-index: 1;

  border-radius: 50%;
  box-shadow: 0px 2px 19px 0px rgba(0, 0, 0, 0.2);

  transition: 0.4s;

  &:hover {
    opacity: 0.8;
  }

  @media (min-width: ${breakpoints.phone}px) {
    top: 35%;
  }

  @media (min-width: ${breakpoints.tablet}px) {
    transform: rotate(0);
    right: 30px;
    top: 45%;
  }

  @media (min-width: ${breakpoints.desktop}px) {
    top: 50%;
  }
`

const HotspotSliderBullets = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  margin: 0 auto;

  @media (min-width: ${breakpoints.desktop}px) {
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    height: 107px;
  }
`

const HotspotSlider = () => {
  const size = useWindowSize()

  const [width, setWidth] = useState(null)

  const switchLeft = useRef(null)
  const switchRight = useRef(null)

  const [slidePosition, setSlidePosition] = useState(0)

  /* Manage Next, Previous event  */
  const handleSliderPosition = i => {
    setSlidePosition(i)
  }

  useEffect(() => {
    setWidth(size.width)
  }, [size])

  return (
    <HotspotSliderContainer>
      <HotspotSlides className="HotspotSlides">
        {sliderHotspots.map((item, index) => (
          <HotspotSlide
            key={"HotspotSlide" + index}
            content={item}
            currentSlidePosition={slidePosition}
          />
        ))}
      </HotspotSlides>

      {slidePosition < 0 && (
        <HotspotSliderNavSwitchLeft
          ref={switchLeft}
          onClick={() => handleSliderPosition(slidePosition + 1)}
        >
          <img
            src={width >= breakpoints.tablet ? arrow : arrowRectangle}
            alt="Previous"
          />
        </HotspotSliderNavSwitchLeft>
      )}

      {slidePosition > -sliderHotspots.length + 1 && (
        <HotspotSliderNavSwitchRight
          ref={switchRight}
          onClick={() => handleSliderPosition(slidePosition - 1)}
        >
          <img
            src={width >= breakpoints.tablet ? arrow : arrowRectangle}
            alt="Next"
          />
        </HotspotSliderNavSwitchRight>
      )}

      <HotspotSliderBullets>
        {sliderHotspots.map((item, index) => (
          <HotspotSliderBullet
            key={"HotspotSliderBullet" + index}
            content={item}
            bulletId={index}
            handleChangeBullet={handleSliderPosition}
            currentSlidePosition={slidePosition}
          />
        ))}
      </HotspotSliderBullets>
    </HotspotSliderContainer>
  )
}

export default HotspotSlider
