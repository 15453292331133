import React, { useState } from "react"
import styled from "styled-components"
import { TweenMax, Power3 } from "gsap"

//styles:
import colors from "../../styles/colors"
import fonts from "../../fonts"
import breakpoints from "../../styles/breakpoints"

//grid:
import Wrapper from "../grid/wrapper"
import Row from "../grid/row"
import Column from "../grid/column"

//images:
import arrow from "../../images/arrow-slider.svg"
import kitchensSlider from "../../data/kitchensSlider"

import ScrollAnimation from "react-animate-on-scroll"

const Anchor = styled.div`
  display: block;
  position: relative;
  top: -60px;
  visibility: hidden;
`

const SliderTitle = styled.h2`
  font-family: ${fonts.life};
  font-weight: normal;
  color: ${colors.tertiaryDark};
  text-align: center;
  padding: 30px 0;
  margin: 0;

  font-size: 23px;
  line-height: 26px;

  span {
    font-weight: bold;
    font-family: ${fonts.helvetica};
  }

  @media ${breakpoints.phone} {
    padding: 0 0 100px;

    font-size: 28px;
    line-height: 32px;
  }
`

const Slider = styled.div`
  display: flex;
  align-items: center;

  position: relative;
  left: 0;
  width: 100%;

  margin: 0 -1px;

  @media ${breakpoints.tablet} {
    max-height: inherit;
  }

  img {
    min-width: 100%;
    padding: 0 1px;
    object-fit: cover;
    max-width: 100%;
  }
`

const ServiceSliderNavSwitchLeft = styled.div`
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: -23px;

  margin-top: -30px;

  user-select: none;
  z-index: 1;

  transform: rotate(180deg);

  border-radius: 50%;
  /* box-shadow: 0px 2px 19px 0px rgba(0,0,0, .2); */
  transition: 0.4s;

  &:hover {
    opacity: 0.8;
  }
`

const ServiceSliderNavSwitchRight = styled.div`
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: -23px;
  margin-top: -30px;
  user-select: none;
  z-index: 1;
  border-radius: 50%;
  /* box-shadow: 0px 2px 19px 0px rgba(0,0,0, .2); */
  transition: 0.4s;

  &:hover {
    opacity: 0.8;
  }
`

const SliderSection = () => {
  const [index, setIndex] = useState(0)

  const animate = i => {
    const slider = document.getElementsByClassName("slide")

    TweenMax.to(slider, 1.4, {
      autoAlpha: 1,
      x: (index + i) * 100 + "%",
      ease: Power3.easeInOut,
    })
  }

  const handlePrevious = i => {
    setIndex(index + i)
    animate(i)
  }

  const handleNext = i => {
    setIndex(index + i)
    animate(i)
  }

  return (
    <section>
      <Anchor id="sliderSection" />
      <Wrapper>
        <Row>
          <Column xs={10} align="center">
            <SliderTitle>
              <ScrollAnimation animateIn="fadeIn">
                <span>Roca llega </span>donde empiezan las mañanas y acaban las
                fiestas, donde la familia la forman los que se sientan en tu
                mesa, donde se endulzan los problemas y se salpimenta la rutina,
                donde te premias cada día con un capricho y alimentas a los
                tuyos con cariño, donde…
              </ScrollAnimation>
            </SliderTitle>
          </Column>

          <Column xs={12}>
            <ScrollAnimation animateIn="fadeInRight">
              <Slider>
                {kitchensSlider.map((kitchen, idx) => (
                  <img
                    className="slide"
                    alt="kitchen 1"
                    src={kitchen}
                    key={idx}
                  />
                ))}

                {index !== 0 && (
                  <ServiceSliderNavSwitchLeft onClick={() => handlePrevious(1)}>
                    <img src={arrow} alt="Previous" />
                  </ServiceSliderNavSwitchLeft>
                )}

                {-index !== kitchensSlider.length - 1 && (
                  <ServiceSliderNavSwitchRight onClick={() => handleNext(-1)}>
                    <img src={arrow} alt="Next" />
                  </ServiceSliderNavSwitchRight>
                )}
              </Slider>
            </ScrollAnimation>
          </Column>
        </Row>
      </Wrapper>
    </section>
  )
}

export default SliderSection
