export default [
    {
        title:"Urban concrete",
        desc:"Para los amantes del estilo industrial.",
        image: "./hotspots-rocacocinas-01.jpg",
        link:"http://publications.eu.roca.com/42322/1047431/#page=14",
        hotspots: [
            {
                text: "Los tiradores vistos son prácticos y funcionales, y contamos con múltiples variantes en el cátalogo de Roca Cocinas.",
                link: "http://publications.eu.roca.com/42322/1047431/#page=57",
                top: 55,
                left: 25
            },
            {
                text: "Los acabados laminados estructurados tipo cemento combinan perfectamente con los acabados laminados de madera, creando un ambiente moderno y cálido.",
                link: "http://publications.eu.roca.com/42322/1047431/#page=49",
                top: 25,
                left: 50
            },
            {
                text: "Las encimeras laminadas de alta presión Roca se pueden adquirir en diferentes grosores.",
                link: "http://publications.eu.roca.com/42322/1047431/#page=59",
                top: 50,
                left: 60
            }
        ]
    },
    {
        title:"Wood balance",
        desc:"Elegancia y calidez para los que lo quieren todo.",
        image: "./hotspots-rocacocinas-02.jpg",
        link:"http://publications.eu.roca.com/42322/1047431/#page=18",
        hotspots: [
            {
                text: "La luz LED ha transformado la iluminación en la cocina. En Roca Cocinas contamos con prácticas soluciones que se adaptan a tus necesidades.",
                link: "http://publications.eu.roca.com/42322/1047431/#page=37",
                top: 30,
                left: 40
            },
            {
                text: "Grifería Mencia y fregadero Praga enrasado, una combinación fantástica.",
                link: "http://publications.eu.roca.com/42322/1047431/#page=63",
                top: 42,
                left: 48
            },
            {
                text: "Hay múltiples soluciones para poder colgar utensilios en la cocina y tenerlos siempre a mano.",
                link: "http://publications.eu.roca.com/42322/1047431/#page=17",
                top: 35,
                left: 67
            },
            {
                text: "Las encimeras de laminado de alta presión permiten realizar mobiliario auxiliar como barras o mesas.",
                link: "http://publications.eu.roca.com/42322/1047431/#page=27",
                top: 50,
                left: 75
            }
        ]
    },
    {
        title:"White cosmopolitan",
        desc:"Cocinas de diseño con un toque funcional.",
        image: "./hotspots-rocacocinas-03.jpg",
        link:"http://publications.eu.roca.com/42322/1047431/#page=22",
        hotspots: [
            {
                text: "Color lacado brillante, un clásico atemporal. Más colores en el catálogo de Roca Cocinas.",
                link: "http://publications.eu.roca.com/42322/1047431/#page=51",
                top: 40,
                left: 20
            },
            {
                text: "La apertura en gola es cada vez más popular. Se pueden crear ambientes modernos con lineas simples.",
                link: "http://publications.eu.roca.com/42322/1047431/#page=57",
                top: 60,
                left: 60
            },
            {
                text: "Las islas abiertas al comedor son tendencia. Con Roca Cocinas puedes crear la isla que mejor se adapte a cada espacio.",
                link: "http://publications.eu.roca.com/42322/1047431/#page=29",
                top: 70,
                left: 75
            }
        ]
    }
]