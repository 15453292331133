import React from "react"

import SEO from "../components/seo"
import Map from "../components/map"
import Experiences from "../components/experiences"
import Cocinas from "../components/cocinas"
import SliderBlocks from "../components/sliderBlocks"
import Hotspots from "../components/hotpost"
import Contact from "../components/contact"
import Layout from "../components/layout"
import NavBar from "../components/navBar"
import Hero from "../components/hero"
import SliderSection from "../components/sliderSection"
import Footer from "../components/footer"
import Catalogo from "../components/catalogo"
import Negocio from "../components/negocio"
import ContactPopup from "../components/contact/contactPopup"
import VideoSection from "../components/VideoSection"

const IndexPage = () => (
  <Layout>
    <SEO title=" " description="" lang="es" />
    <NavBar />
    <Hero />
    <VideoSection />
    <SliderSection />
    <Experiences />
    <Hotspots />
    <SliderBlocks />
    <Cocinas />
    <Catalogo />
    <Map />
    <Contact />
    <Negocio />
    <Footer />
    <ContactPopup />
  </Layout>
)

export default IndexPage
