import React from 'react'
import styled from "styled-components"

import fonts from "../../fonts"

const ExperiencesCtaContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    width: 100%;
    padding: 30px 30px 40px;
    margin: 15px 0;
    height: calc(100% - 30px);
    box-sizing: border-box;

    box-shadow: 0px 2px 19px 0px rgba(0,0,0,0.2);
`

const ExperiencesNumber = styled.span`
    font-size: 65px;
    line-height: 93px;
    font-family: ${fonts.life};
`

const ExperiencesTitle = styled.h3`
    font-size: 19px;
    line-height: 21px;
    font-weight: bold;
    font-family: ${fonts.helvetica};

    margin: 0 0 40px;
`

const ExperiencesDesc = styled.p`
    font-size: 16px;
    line-height: 24px;
    font-weight: lighter;
    font-family: ${fonts.helvetica};

    margin: 0 0 20px 0;
`

const ExperiencesImage = styled.img`
    margin: auto;
    max-width:60%;
`

const ExperiencesCta = ({number, title, text, image}) => {
    
    return (

        <ExperiencesCtaContainer>

            <ExperiencesNumber>
                {number}
            </ExperiencesNumber>

            <ExperiencesTitle>
                {title}
            </ExperiencesTitle>

            <ExperiencesDesc>
                {text}
            </ExperiencesDesc>

            <ExperiencesImage src={image} alt={title} />

        </ExperiencesCtaContainer>

     )
}

export default ExperiencesCta