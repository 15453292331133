import React, { useState } from 'react'
import styled from 'styled-components'
import axios from 'axios'
import CryptoJS from "crypto-js"

import colors from '../../styles/colors'
import fonts from '../../fonts'
import breakpoints from '../../styles/breakpoints'

import Arrow from '../../images/drop_arrow.svg'

import Wrapper from "../grid/wrapper"
import Row from "../grid/row"
import Column from "../grid/column"

import { useInput } from './hooks/inputHook'


/* eslint-disable */


const ContactFormS = styled.div`

  font-family: ${fonts.helvetica};
  margin: 0 -15px;

  @media ${breakpoints.tablet}{
    margin: 0;
  }

  form{
    display: flex;
    flex-direction: column;
  }

  textarea{
    width: 100%;
    background: ${colors.tertiary};
    border: 1px solid rgb(232, 232, 232);
    padding: 16px;
    margin-bottom: 30px;
    min-height: 140px;
    color: ${colors.tertiaryLight};
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 20px;
    outline: none;

    transition: all .4s ease;

    &:hover { 
      border: 1px solid ${colors.tertiaryLight};
    }

    &:focus { 
      border: 1px solid ${colors.primaryDark};
    }

    ::placeholder{
      font-family: ${fonts.helvetica};
      color: ${colors.tertiaryLight};
      font-size: 14px;
      font-weight: normal;
      letter-spacing: 0px;
      line-height: 21px;
    }
  }

  label {
    width: fit-content;
  }

  .legend{
    color: ${colors.tertiaryDark};
    font-size: 14px;
    line-height: 21px;
  }
  .legend a {
    color: ${colors.tertiaryDark};
    transition: all .4s ease;
    
    :hover {
      text-decoration: underline;
      color: ${colors.primaryLight};
    }
  }
  
`

const InputsColumns = styled.div`
  display: flex;
  flex-flow: column wrap;
  align-content: stretch;
  justify-content: space-between;

  input{
    background: ${colors.tertiary};
    border: 1px solid rgb(232, 232, 232);
    padding: 0 16px;
    width: 100%;
    height: 52px;
    margin-bottom: 15px;
    color: ${colors.primaryDark};
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 21px;
    outline: none;
    
    transition: all .4s ease;

    &:hover { 
      border: 1px solid ${colors.tertiaryLight};
    }

    &:focus { 
      border: 1px solid ${colors.primaryDark};
    }

  }

  select{
    display: flex;
    justify-content: space-between;
    background: ${colors.tertiary};
    border: 1px solid rgb(232, 232, 232);
    border-radius: 0;
    width: 100%;
    padding: 0 16px;
    margin-bottom: 15px;
    color: ${colors.tertiaryLight};
    font-size: 14px;
    font-weight: normal;
    outline: none;
    height: 52px;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: url(${Arrow}) no-repeat right;
    background-position-x: 98%;

    transition: all .4s ease;

    &:hover { 
      border: 1px solid ${colors.tertiaryLight};
    }

    &:focus { 
      border: 1px solid ${colors.primaryDark}
    }
  }

  option {
    -webkit-appearance: none;
    -moz-appearance: none;
  }

  input::placeholder, select::placeholder{
    font-family: ${fonts.helvetica};
    color: ${colors.tertiaryLight};
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 21px;
  }
`

const InputHidded = styled.input`
  opacity: 0;
  visibility: hidden;
`

const BtnSubmit = styled.button`
  width: fit-content;
  margin: 30px 0 0;
  background-color: ${colors.primary};
  border: 1px solid ${colors.primary};
  padding: 10px 60px;
  font-family: ${fonts.helvetica};
  font-size: 14px;
  font-weight: bold;
  color: ${colors.tertiary};
  cursor: pointer;
  transition: all .4s ease;
  
  &:hover {
    background-color: ${colors.primaryLight};
    border: 1px solid ${colors.primaryLight};
  }
`

const Status = styled.div`
  margin: 15px;
  font-family: ${fonts.helvetica};
  font-size: 14px;
  font-weight: bold;
  color: ${colors.tertiaryDark};
`

function ContactForm (props){

  const [state, setState] = useState({ sending: false, mailSent: null, error: null })
  const [likeCommunicationsValueChecked, setLikeCommunicationsValueChecked] = useState(false)
  const [dataPolicyValueChecked, setDataPolicyValueChecked] = useState(false)

  /* Fields to change  */

  /* Value fields */
  const template = "form-roca-cocinas" // The name of the file sent to Roca 

  const { value:nameValue, bind:bindName, reset:resetName } = useInput('')
  const { value:surnameValue, bind:bindSurename, reset:resetSurename } = useInput('')
  const { value:phoneValue, bind:bindPhone, reset:resetPhone } = useInput('')
  const { value:zipCodeValue, bind:bindPostalcode, reset:resetPostalcode } = useInput('')
  const { value:districtValue, bind:bindProvincia, reset:resetProvincia } = useInput('')
  const { value:cityValue, bind:bindPoblacion, reset:resetPoblacion } = useInput('')
  const { value:emailValue, bind:bindEmailValue, reset:resetEmailValue } = useInput('')
  const { value:helpValue, bind:bindMessage, reset:resetMessage } = useInput('')

  /* Label fields */
  const nameLabel="Nombre"
  const surnameLabel="Apellidos"
  const emailLabel="Email"
  const phoneLabel="Teléfono"
  const zipCodeLabel="Código postal"
  const districtLabel="Provincia"
  const cityLabel="Población"
  const helpLabel="¿En qué podemos ayudarte?"

  /*****************************/

  /* Those fields are obligatory */

  const { value:dataPolicyValue, bind:bindDataPolicyValue, reset:resetMessageDataPolicyValue } = useInput('')
  const { value:likeCommunicationsValue, bind:bindLikeCommunicationsValue, reset:resetLikeCommunicationsValue } = useInput('')
  const { value:emailTo, bind:bindEmailTo } = useInput('marc.sole.villaverde@roca.net, ricardo.berruezo@roca.net, judit.porras@roca.net') // People how receive the mail
  const { value:subject, bind:bindSubject } = useInput('Roca Cocinas') // Title of the received email

  const emailFrom = emailValue // This field don't have to be changed only is value

  /*****************************/

  /* Default code given by roca */

  Date.prototype.ddmmyyyy = function() {
    var mm = this.getMonth() + 1
    var dd = this.getDate();

    return [(dd>9 ? '' : '0') + dd + '/',
        (mm>9 ? '' : '0') + mm + '/',
        this.getFullYear()
       ].join('');
  }

  function generateToken(idSession) {
    var date = new Date();
    var sDate = date.ddmmyyyy();
    var aux_text = "!sAntr03A;";			
  
  
    var aux = sDate + "." + idSession;
    var encryptedSHA256 = CryptoJS.SHA256(aux).toString(CryptoJS.enc.utf8).toUpperCase();
    var aux2 = aux_text + "." + encryptedSHA256;
    var token = CryptoJS.MD5(aux2).toString(CryptoJS.enc.utf8);
    
    return token.toUpperCase();
  }

  /*****************************/

  const handleSubmit = (e) => {
    e.preventDefault();

    setState({...state, sending: true})

    const formData = new FormData(document.getElementById("form"))
    
    /* Default code given by roca */
    /* Set Cookies and Token */

    const session = document.cookie !== null && document.cookie !== "" && document.cookie.match('_ga=([^;]*)')[1]
    const token = document.cookie !== null && document.cookie !== "" && generateToken(document.cookie.match('_ga=([^;]*)')[1])

    /*****************************/
    
    /* Fields to change  */
    
    const literals = []
    literals.push({nameLabel : nameLabel})
    literals.push({nameValue : nameValue})
    literals.push({surnameLabel : surnameLabel})
    literals.push({surnameValue : surnameValue})
    literals.push({emailLabel : emailLabel})
    literals.push({emailValue : emailValue})
    literals.push({phoneLabel : phoneLabel})
    literals.push({phoneValue : phoneValue})
    literals.push({zipCodeLabel : zipCodeLabel})
    literals.push({zipCodeValue : zipCodeValue})
    literals.push({districtLabel : districtLabel})
    literals.push({districtValue : districtValue})
    literals.push({cityLabel : cityLabel})
    literals.push({cityValue : cityValue})
    literals.push({helpLabel : helpLabel})
    literals.push({helpValue : helpValue})
    
    /*****************************/
    
    /* Those fields are obligatory */

    literals.push({dataPolicyValue : dataPolicyValueChecked})
    literals.push({likeCommunicationsValue : likeCommunicationsValueChecked})

    literals.push({emailTo : emailTo})
    literals.push({emailFrom : emailFrom})
    literals.push({subject : subject})
    
    formData.append("session", session)
    formData.append("token", token)
    formData.append("template", template)
    formData.append("literals", JSON.stringify([literals]))

    /*****************************/

    axios({
      method: 'POST',
      url: "https://" + window.location.hostname + "/roca-web/common/sendMail",
      config: { headers: {'Content-Type': 'multipart/form-data' }},
      data: formData
    })
    .then(result => {
      setState({...state, mailSent: true, sending: false })
      resetName()
      resetSurename()
      resetPhone()
      resetPostalcode()
      resetProvincia()
      resetPoblacion()
      resetEmailValue()
      resetMessage()
      resetMessageDataPolicyValue()
      resetLikeCommunicationsValue()
      console.log(state)
    })
    .catch(
      error => {
        console.log(error)
        setState({...state, error: error.message })
      }
    )
    
  }

  return(
      
      <Wrapper>

        <ContactFormS>
          <form id="form" onSubmit={handleSubmit}>
          <InputsColumns>
            <Row>
              <Column xs={12} md={6}>
                <input aria-label="Nombre" name="nameValue" placeholder="Nombre*" required={true} type="text" { ...bindName } />
              </Column>
              <Column xs={12} md={6}>
                <input aria-label="Apellidos" name="surnameValue" placeholder="Apellidos*" required={true} type="text" { ...bindSurename } />
              </Column>
              </Row>

              <Row>
              <Column xs={12} md={6}>
                <input aria-label="Email" name="emailFrom" placeholder="Email*" required={true} type="email" { ...bindEmailValue } />
              </Column>
              <Column xs={12} md={6}>
                <input aria-label="Teléfono" name="phoneValue" placeholder="Teléfono*" required={true} type="text" { ...bindPhone } />
              </Column>
              </Row>

              <Row>
              <Column xs={12} md={6}>
                <input aria-label="Código postal" name="zipCodeValue" placeholder="Código postal*" required={true} type="text" { ...bindPostalcode } />
              </Column>
              <Column xs={12} md={6}>
                <select aria-label="Provincia" name="districtValue" required={true} { ...bindProvincia }>
                  <option value='' disabled={true} defaultValue={true}>Provincia*</option>
                  <option value='alava'>Álava</option>
                  <option value='albacete'>Albacete</option>
                  <option value='alicante'>Alicante/Alacant</option>
                  <option value='almeria'>Almería</option>
                  <option value='asturias'>Asturias</option>
                  <option value='avila'>Ávila</option>
                  <option value='badajoz'>Badajoz</option>
                  <option value='barcelona'>Barcelona</option>
                  <option value='burgos'>Burgos</option>
                  <option value='caceres'>Cáceres</option>
                  <option value='cadiz'>Cádiz</option>
                  <option value='cantabria'>Cantabria</option>
                  <option value='castellon'>Castellón/Castelló</option>
                  <option value='ceuta'>Ceuta</option>
                  <option value='ciudadreal'>Ciudad Real</option>
                  <option value='cordoba'>Córdoba</option>
                  <option value='cuenca'>Cuenca</option>
                  <option value='girona'>Girona</option>
                  <option value='laspalmas'>Las Palmas</option>
                  <option value='granada'>Granada</option>
                  <option value='guadalajara'>Guadalajara</option>
                  <option value='guipuzcoa'>Guipúzcoa</option>
                  <option value='huelva'>Huelva</option>
                  <option value='huesca'>Huesca</option>
                  <option value='illesbalears'>Illes Balears</option>
                  <option value='jaen'>Jaén</option>
                  <option value='acoruña'>A Coruña</option>
                  <option value='larioja'>La Rioja</option>
                  <option value='leon'>León</option>
                  <option value='lleida'>Lleida</option>
                  <option value='lugo'>Lugo</option>
                  <option value='madrid'>Madrid</option>
                  <option value='malaga'>Málaga</option>
                  <option value='melilla'>Melilla</option>
                  <option value='murcia'>Murcia</option>
                  <option value='navarra'>Navarra</option>
                  <option value='ourense'>Ourense</option>
                  <option value='palencia'>Palencia</option>
                  <option value='pontevedra'>Pontevedra</option>
                  <option value='salamanca'>Salamanca</option>
                  <option value='segovia'>Segovia</option>
                  <option value='sevilla'>Sevilla</option>
                  <option value='soria'>Soria</option>
                  <option value='tarragona'>Tarragona</option>
                  <option value='santacruztenerife'>Santa Cruz de Tenerife</option>
                  <option value='teruel'>Teruel</option>
                  <option value='toledo'>Toledo</option>
                  <option value='valencia'>Valencia/Valéncia</option>
                  <option value='valladolid'>Valladolid</option>
                  <option value='vizcaya'>Vizcaya</option>
                  <option value='zamora'>Zamora</option>
                  <option value='zaragoza'>Zaragoza</option>
                </select>
              </Column>
              </Row>
              
              <Row>
                <Column xs={12} md={6}>
                  <input aria-label="Población" name="cityValue" placeholder="Población*" required={true} type="text" { ...bindPoblacion } />
                </Column>
              </Row>
            </InputsColumns>

            <Row>
              <Column xs={12}>
                <textarea aria-label="Mensaje" name="helpLabel" placeholder="¿En qué podemos ayudarte?" { ...bindMessage } />
              </Column>
            </Row>

            <Row>
              <Column xs={12}>
                <label htmlFor="terms" className="legend"><input type="checkbox" id="terms" name="dataPolicyValue" required={true} onClick={ () => {setDataPolicyValueChecked(!dataPolicyValueChecked)} } { ...bindDataPolicyValue } />  He leído y acepto la <a href="https://www.roca.es/politica-de-privacidad" target="_blank" rel="noopener noreferrer">política de Protección de Datos</a></label>
              </Column>
              <Column xs={12}>
                <label htmlFor="comercial" className="legend"><input type="checkbox" id="comercial" name="likeCommunicationsValue" onClick={ () => {setLikeCommunicationsValueChecked(!likeCommunicationsValueChecked)} } { ...bindLikeCommunicationsValue }/>  Deseo recibir comunicaciones comerciales por parte de Roca.es</label>
              </Column>
            </Row>

            {/* Obligatory elements to valid the POST of the form */}

            <InputHidded type="email" name="emailTo" readOnly { ...bindEmailTo } />
            <InputHidded type="text" name="subject" readOnly { ...bindSubject } />
            
            <BtnSubmit aria-label="Enviar" type="submit">Enviar</BtnSubmit>
            
            <Status>
              { state.sending && <span>Enviando...</span>}
              { state.error && <span>{ state.error }</span> }
              { state.mailSent && <span>Hemos recibido tu mensaje. En breve nos pondremos en contacto contigo.</span> }
            </Status>
          </form>
        </ContactFormS>

      </Wrapper>
  )
}

export default ContactForm