import React, { useState } from "react"
import styled from "styled-components"
import fonts from "../../fonts/index"
import colors from "../../styles/colors"

import ContactIcon from "../../images/contact_icon.svg"
import CloseButtonImg from "../../images/close_button.svg"

const Container = styled.div`
  position: fixed;
  display: ${({ closed }) => closed && "none"};
  user-select: none;
  right: 20px;
  bottom: 20px;
`

const Popup = styled.a`
  border-radius: 50%;
  background: white;
  box-shadow: 0 2px 15px -3px rgba(0, 0, 0, 50);
  padding: 10px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  height: 120px;
  width: 120px;

  font-family: ${fonts.helvetica};
  color: ${colors.primaryDark};
  text-decoration: none;
  font-size: 14px;
  font-weight: bold;
  line-height: 18px;
`

const PopupImage = styled.img`
  width: 40px;
  margin: 0 auto;
`
const CloseButton = styled.div`
  width: 30px;
  height: 30px;
  right: 10px;
  top: 0;
  border-radius: 50%;
  position: absolute;
  background-image: url(${CloseButtonImg});
  background-size: cover;
  background-position: center;

  cursor: pointer;
`

const ContactPopup = () => {
  const [closed, setClosed] = useState(false)

  const handleClose = () => setClosed(true)

  return (
    <Container closed={closed}>
      <Popup href={"#pidepresupuesto"}>
        <p>Pide tu presupuesto</p>
          <PopupImage src={ContactIcon} alt="contact icon"/>
      </Popup>
      <CloseButton onClick={handleClose} />
    </Container>
  )
}

export default ContactPopup
