import React from "react"
import styled from "styled-components"

import Wrapper from "../grid/wrapper"
import Row from "../grid/row"
import Column from "../grid/column"

import breakpoints from "../../styles/breakpoints"
import colors from "../../styles/colors"

import ContactForm from "./contactForm"
import ScrollAnimation from "react-animate-on-scroll"

//img:
import Phone from "../../images/phone.svg"

const SectionContainer = styled.section`
  width: 100%;
`

const Anchor = styled.div`
  display: block;
  position: relative;
  top: -60px;
  visibility: hidden;
`

const ContactContainer = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  flex-direction: column;
`
const ContactHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`
const ContactContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`
const ContactTitle = styled.div`
  font-size: 33px;
  line-height: 37px;
  font-weight: bold;
  margin-bottom: 0;
  margin-right: 0;
  text-align: center;

  @media ${breakpoints.tablet} {
    margin-bottom: 0;
    margin-right: 0;
    font-size: 48px;
    line-height: 54px;
  }
`

const ContactSubtitle = styled.div`
  font-size: 20px;
  line-height: 30px;
  margin: 20px auto 20px;
  text-align: center;
`

const ContactTelf = styled.a`
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;

  color: ${colors.tertiaryDark};

  text-decoration: underline;
  transition: all 0.4s ease;
  opacity: 1;

  :hover {
    opacity: 0.6;
  }
`

const PhoneIcon = styled.img`
  height: 26px;
  margin-right: 10px;
`

const ContactContentText = styled.div`
  font-size: 16px;
  line-height: 24px;
  margin: 55px auto 0;

  @media ${breakpoints.tablet} {
    margin: 55px auto 25px;
  }
`

const Contact = () => {
  return (
    <SectionContainer>
      <Anchor id="pidepresupuesto" />
      <Wrapper>
        <ContactContainer>
          <Row>
            <Column xs={12}>
              <ContactHeader>
                <ContactTitle>
                  <ScrollAnimation animateIn="fadeIn">
                    Pide tu presupuesto
                  </ScrollAnimation>
                </ContactTitle>
                <ContactSubtitle>
                  <ScrollAnimation animateIn="fadeIn">
                    Llámanos y te daremos cita para visitar tu tienda Roca más
                    cercana y tener el presupuesto de tu cocina
                  </ScrollAnimation>
                </ContactSubtitle>
                <ContactTelf href="tel:+34 933661266">
                  <PhoneIcon alt="phone-icon" src={Phone} /> 93 366 12 66
                </ContactTelf>
              </ContactHeader>
            </Column>
          </Row>
          <Row>
            <Column xs={12}>
              <ContactContent>
                <ContactContentText>
                  O si lo prefieres utiliza nuestro formulario de contacto{" "}
                </ContactContentText>
              </ContactContent>
            </Column>
          </Row>

          <ContactForm />
        </ContactContainer>
      </Wrapper>
    </SectionContainer>
  )
}

export default Contact
