import React, { useState, useEffect } from "react"
import styled from "styled-components"
import fonts from "../../fonts"
import colors from "../../styles/colors"
import breakpoints from "../../styles/breakpoints"
import Wrapper from "../grid/wrapper"
import GoogleMapReact from "google-map-react"
import ScrollAnimation from "react-animate-on-scroll"
import { MapContext } from "./mapContext"
import mapInfo from "../../data/mapInfo"
import Marker from "./marker"
import InfoItems from "./infoItems"

const Anchor = styled.div`
  display: block;
  position: relative;
  top: -60px;
  visibility: hidden;
`
const MapSection = styled.section`
  width: 100%;
`
const MapSectionContainer = styled.div`
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${colors.primary};
  font-family: ${fonts.helvetica};

  p {
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    margin: 0 auto 30px;

    @media ${breakpoints.phone} {
      margin: 0 auto 60px;
    }
  }
`

const MapSectionTitle = styled.h2`
  font-size: 33px;
  line-height: 37px;
  font-weight: bold;
  text-align: center;
  margin: 50px auto 20px;

  @media ${breakpoints.phone} {
    font-size: 48px;
    line-height: 54px;
    margin: 50px auto 30px;
  }
`

const MapContainer = styled.div`
  width: 100%;
  height: 50vh;
  margin: 0;
`
const Map = () => {
  const sortedByProvinceItems = mapInfo.sort((a, b) =>
    a.provincia < b.provincia ? -1 : a.provincia > b.provincia ? 1 : 0
  )

  const [mapInfoItems, setMapInfoItems] = useState(sortedByProvinceItems)
  const [current, setCurrent] = useState(mapInfoItems[0])

  useEffect(() => {
    setCurrent(mapInfoItems.find(el => el.current === true))
  }, [mapInfoItems, current])

  return (
    <MapSection>
      <Anchor id="donde" />
      <Wrapper>
        <MapSectionContainer>
          <MapContext.Provider value={{ mapInfoItems, setMapInfoItems }}>
            <ScrollAnimation animateIn="fadeIn">
              <MapSectionTitle>Dónde comprar</MapSectionTitle>
              <p>El lugar donde tomar la decisión correcta</p>
            </ScrollAnimation>
            <MapContainer>
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: "AIzaSyCNlQl7w8f11b4Rfd5uchqPbS4ju_dhr28",
                }}
                defaultCenter={mapInfoItems[0]}
                defaultZoom={5.3}
                center={{
                  lat: 40.02076,
                  lng: -3.6091987,
                }}
              >
                {mapInfoItems.map((item, index) => (
                  <Marker
                    key={`marker${item.id}`}
                    markerIndex={index}
                    lat={item.coordenadas.lat}
                    lng={item.coordenadas.lng}
                  />
                ))}
              </GoogleMapReact>
            </MapContainer>
            <InfoItems />
          </MapContext.Provider>
        </MapSectionContainer>
      </Wrapper>
    </MapSection>
  )
}

export default Map
