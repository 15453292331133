import React, {useEffect} from "react"
import styled from "styled-components"
import {TweenMax, Power3} from 'gsap'

import colors from '../../styles/colors'
import fonts from "../../fonts"
import breakpoints from "../../styles/breakpoints"


const SliderSectionSlideTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    
    width: 100%;

    overflow: hidden;
    color: ${colors.tertiary};
    background-color: ${colors.primary};

    order: 1;

    @media ${breakpoints.phone} {
        ${({sliderId}) => sliderId % 2 === 0 ? "order: 0;" : "order: 1;"  }
        width: 50%;
    }
`

const SliderSectionSlideTextContainerSlide = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: auto;
`

const SliderSectionSlideTextContainerSlideTextTitle = styled.h3`
    font-size: 28px;
    font-family: ${fonts.helvetica};
    font-weight: bold;
    line-height: 32px;

    margin: 0 0 20px;
    padding: 30px 30px 0 30px;

    @media ${breakpoints.tablet} {
        padding: 60px 60px 0 60px;
    }
`

const SliderSectionSlideTextContainerSlideTextDesc = styled.p`
    font-size: 16px;
    font-family: ${fonts.helvetica};
    line-height: 24px;

    margin: 0 0 20px;
    padding: 0 30px;

    @media ${breakpoints.tablet} {
        padding: 0 60px;
    }
`

const SliderSectionSlideTextContainerSlideTextLink = styled.a`
    color: ${colors.tertiary};
    font-size: 14px;
    font-family: ${fonts.helvetica};
    line-height: 21px;
    
    margin: 0;
    padding: 0 30px;

    transition: all .4s ease;

    @media ${breakpoints.tablet} {
        padding: 0 60px;
    }

    :hover {
        text-decoration: underline;
        color: ${colors.primaryLight};
    }
`


const SliderSectionSlideTextContainerSlideTagContainer = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    min-width: 100%;
    margin-top: 20px;

    padding: 0 30px;
    box-sizing: border-box;

    @media ${breakpoints.phone} {
        margin: 0;
    }

    @media ${breakpoints.tablet} {
        padding: 0 60px;
    }
`

const SliderSectionSlideTextContainerSlideTextTag = styled.span`
    font-size: 20px;
    font-family: ${fonts.helvetica};
    font-weight: bold;
    line-height: 30px;
`

const SliderSectionSlideTextCounter = styled.div`
    display: flex;
    
    padding: 15px 30px 60px;

    @media ${breakpoints.tablet} {
        padding: 15px 60px 60px;
    }
`

const SliderSectionSlideTextCounterBullet = styled.div`
    min-height: 10px;
    min-width: 10px;
    border-radius: 50%;
    background-color: ${colors.tertiary};
    cursor: pointer;

    + div {
        margin-left: 13px
    }
`


const SliderSectionSlideText = ({content, sliderId, currentSlidePosition, bullet}) => {

    useEffect(() => {

        /* Determine which bullet are updated */
        
        /* sliderId is the id of the parent slider */
        const bullet = document.getElementsByClassName('bullet' + sliderId)
        /* currentSlidePosition is the current position  */
        const bulletActive = document.getElementsByClassName('bullet' + sliderId + (-currentSlidePosition))

        TweenMax.to(bullet, 1,{
            opacity: .2,
            ease: Power3.easeInOut
        })
        
        TweenMax.to(bulletActive, 1, {
            opacity: 1,
            ease: Power3.easeInOut
        })

    }, [currentSlidePosition, sliderId])

    return (

        <SliderSectionSlideTextContainer sliderId={sliderId}>

            <SliderSectionSlideTextContainerSlideTextTitle>
                {content.title}
            </SliderSectionSlideTextContainerSlideTextTitle>

            <SliderSectionSlideTextContainerSlideTextDesc>
                {content.desc}
            </SliderSectionSlideTextContainerSlideTextDesc>

            <SliderSectionSlideTextContainerSlideTextLink href={content.link} target="_blank">Más detalles</SliderSectionSlideTextContainerSlideTextLink>

            <SliderSectionSlideTextContainerSlide>

                {content.slides.map( (item, index) => (
                    <SliderSectionSlideTextContainerSlideTagContainer key={"SliderSectionSlideTextContainerSlideTagContainer" + index} className={"slide" + sliderId}>

                        <SliderSectionSlideTextContainerSlideTextTag>
                            {item.tag}
                        </SliderSectionSlideTextContainerSlideTextTag>

                    </SliderSectionSlideTextContainerSlideTagContainer>
                ))}
                
            
            </SliderSectionSlideTextContainerSlide>

            <SliderSectionSlideTextCounter>

                {content.slides.map( (item, index) => <SliderSectionSlideTextCounterBullet key={"SliderSectionSlideTextCounterBullet" + index} className={"bullet" + sliderId + " bullet" + sliderId + index} onClick={ () => bullet(index) } /> )}

            </SliderSectionSlideTextCounter>

        </SliderSectionSlideTextContainer>
     )
}
 
export default SliderSectionSlideText

