import React, {useState, useEffect} from "react"
import styled from 'styled-components'
import {TweenMax, Power3} from 'gsap'

import useWindowSize from '../../utils/useWindowSize'

import colors from '../../styles/colors'
import fonts from "../../fonts"
import {breakpoints} from '../../styles/breakpoints'

// import arrowRightBlack from "../../images/arrow-right-black.svg"

import HotspotItem from "./hotspotItem"

const HotspotSlideContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    min-width: 100%;

    overflow: hidden;

    position: relative;
`

const HotspotSlideImage = styled.img`
    min-width: 100%;
    padding: 0 1px;
    object-fit: cover;
    max-width: 100%;

    order: 1;
    `

const HotspotSlideText = styled.div`
    
    width: 100%;
    box-sizing: border-box;
    background: white;
    padding: 20px;
    min-height: 160px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (min-width: ${breakpoints.phone}px) {
        position: absolute;
        padding: 15px;
        top: 20px;
        left: 20px;
        width: initial;
        min-height: auto;
    }

    @media (min-width: ${breakpoints.desktop}px) {
        position: absolute;
        padding: 30px;
        top: 20px;
        left: 20px;
        width: inherit;
    }
`

const HotspotSlideLink = styled.a`
    display: flex;
    justify-content: space-between;
    align-items: center;

    order: 2;

    color: ${colors.tertiaryDark};
    font-family: ${fonts.helvetica};
    font-size: 14px;
    font-weight: bold;
    line-height: 18px;

    padding: 30px;
    width: 100%;
    box-sizing: border-box;
    background: white;
`

const HotspotSlideTitle = styled.h3`
    color: ${colors.tertiaryDark};
    font-family: ${fonts.helvetica};
    font-size: 27px;
    line-height: 32px;
    font-weight: bold;
    order: 0;
    margin: 0;

    @media (min-width: ${breakpoints.phone}px) {
        font-size: 33px;
        line-height: 37px;
    }
`

const HotspotSlideDesc = styled.p`
    color: ${colors.tertiaryDark};
    font-family: ${fonts.helvetica};
    font-size: 16px;
    line-height: 24px;
    margin: 5px 0 0;

    @media (min-width: ${breakpoints.desktop}px) {
        margin: 20px 0 0;
    }
    `


const HotspotSlide = ({content, sliderId, currentSlidePosition, slideId}) => {

    const size = useWindowSize();

    const [width, setWidth] = useState(null)

    useEffect(() => {

        const sliderSectionSlideImage = document.getElementsByClassName("HotspotSlide")

        TweenMax.to(sliderSectionSlideImage, 1.4, {
            autoAlpha: 1,
            x: currentSlidePosition * 100 + '%',
            ease: Power3.easeInOut
        })

        setWidth(size.width)

    }, [currentSlidePosition, size])
    
    return (
        <HotspotSlideContainer className="HotspotSlide">

            <HotspotSlideImage src={content.image} alt={content.title}/>

            <HotspotSlideText>

                <HotspotSlideTitle>
                    {content.title}
                </HotspotSlideTitle>

                <HotspotSlideDesc>
                    {content.desc}
                </HotspotSlideDesc>

            </HotspotSlideText>

            {width < breakpoints.tablet && <HotspotSlideLink href={content.link} target="_blank" rel="noopener noreferrer">
                Descubre todos los productos {/*<img alt="arrowRightBlack" src={arrowRightBlack}/>*/}
            </HotspotSlideLink>}

            {width >= breakpoints.tablet && content.hotspots.map( (item, index) => <HotspotItem key={"HotspotItem" + index} content={item} hotspotId={index}/>)}

        </HotspotSlideContainer>
    )
}

export default HotspotSlide