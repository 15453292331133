import React from "react"
import styled from "styled-components"
import fonts from "../../fonts"
import colors from "../../styles/colors"
import breakpoints from "../../styles/breakpoints"

import Wrapper from "../grid/wrapper"
import Row from "../grid/row"
import Column from "../grid/column"

//image:
import catalogoBook from "../../images/img-catalogo-cocinas.png"
import todoCocinasBook from "../../images/img-todo-cocinas.png"
import iconVer from "../../images/icon-ver.png"

import ScrollAnimation from "react-animate-on-scroll"

const Anchor = styled.div`
  display: block;
  position: relative;
  top: -100px;
  visibility: hidden;
`

const CatalogoContainer = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: center;

  width: 100%;
  min-height: 200px;
  position: relative;
  margin: 0;
  padding: 20px 0;
  background: ${colors.primary};

  color: ${colors.tertiary};
  font-family: ${fonts.helvetica};
  font-weight: bold;

  @media ${breakpoints.tablet} {
    flex-direction: row;
    padding: 80px 0;
  }

  @media ${breakpoints.desktop} {
    padding: 40px 0;
  }

  a {
    text-decoration: none;
    color: ${colors.primary};
  }
`

const ContenedorCatalogo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  
  @media ${breakpoints.tablet} {
    flex-direction: row;
  }
  
`

const CatalogoLeft = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  
  @media ${breakpoints.tablet} {
    width: 76%;
  }
  

  .animated{
    width:100%;
    
    @media ${breakpoints.desktop} {
      width: auto;
    }
  }
  
  img {
    width: 190px;

    @media ${breakpoints.desktop} {
      width: 170px;
      height: auto;
    }

    @media ${breakpoints.large} {
      width: 200px;
      height: auto;
    }
  }
`

const CatalogoRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  width: 100%;
  margin-top: 15px;

  @media ${breakpoints.tablet} {
    align-items: flex-start;
    margin: 0;
    text-align: start;
    justify-content: flex-start;
  }
`

const Title = styled.h2`
  font-size: 33px;
  line-height: 37px;
  font-weight: bold;
  text-align: center;
  margin: 0 0 50px 30px;

  @media ${breakpoints.phone} {
    font-size: 48px;
    line-height: 54px;
    margin: 0 0 50px 30px;
  }
`

const SubTitle = styled.h2`
  font-size: 28px;
  line-height: 30px;
  margin: 0 0 10px 0;
  
  
  @media ${breakpoints.tablet} {
      font-size: 19px;
      line-height: 21px;
      margin: 20px 0 10px 0;
    }
  
   @media ${breakpoints.desktop} {
      margin: 20px 0 10px 0;
    }
  
  @media ${breakpoints.large} {
      margin: 48px 0 10px 0;
    }
  
`

const Text = styled.p`
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  font-weight:normal;
`

const ButtonsContainer = styled.div`
  display: flex;
  margin-top: 30px;
  justify-content: space-evenly;
  align-items: center;

  @media ${breakpoints.tablet} {
    justify-content: flex-start;

    > a + a {
      margin-left: 30px;
    }
  }
`

const ButtonsContainerVer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  @media ${breakpoints.tablet} {
    justify-content: flex-start;
  }
  
  a:first-child {
    padding-bottom:40px;
  }
  
`

const ButtonLink = styled.a`
  background: ${colors.tertiary};
  padding: 10px 25px;
  border: 1px solid;
  color: ${colors.primary};
  transition: all 0.4s ease;

  :hover {
    color: ${colors.primaryLight};
  }
`

const ButtonLinkVer = styled.a`
  padding: 10px 5px;
  color: ${colors.tertiary}!important;
  transition: all 0.4s ease;

  :hover {
    color: ${colors.primaryLight};
  }
`

const Catalogo = () => (
  <CatalogoContainer>
    <Anchor id="publicaciones" />
    <Wrapper>
      <Row>
        <Column sm={12} align="center">

            <ScrollAnimation animateIn="fadeIn">
              <Title>Publicaciones</Title>
            </ScrollAnimation>

        </Column>
      </Row>
      <Row>
        <Column md={6}>
          <ContenedorCatalogo>
            <CatalogoLeft>
              <ScrollAnimation animateIn="fadeInLeft">
                <img alt="Catalogo - Book" src={catalogoBook} />
              </ScrollAnimation>
            </CatalogoLeft>
            <CatalogoRight>
              <ScrollAnimation animateIn="fadeInRight" delay={100}>
                <SubTitle>Catálogo de Roca Cocinas</SubTitle>
                <Text>Consulta en detalle todas las</Text>
                <Text>características de nuestras cocinas.</Text>
                <ButtonsContainer>
                  <ButtonLink
                      href="Catalogo_Roca_Cocinas.pdf"
                      download="Catalogo_Roca_Cocinas.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                  >
                    DESCARGAR
                  </ButtonLink>
                </ButtonsContainer>
                <ButtonsContainerVer>
                  <ButtonLinkVer
                      href="http://publications.eu.roca.com/42322/1047431/#page=1"
                      target="_blank"
                      rel="noopener noreferrer"
                  >
                    <img alt=">" src={iconVer} /> Ver
                  </ButtonLinkVer>
                </ButtonsContainerVer>
              </ScrollAnimation>
            </CatalogoRight>
          </ContenedorCatalogo>

        </Column>
        <Column md={6}>
          <ContenedorCatalogo>
            <CatalogoLeft>
              <ScrollAnimation animateIn="fadeInLeft">
                <img alt="Todo Cocinas - Book" src={todoCocinasBook} />
              </ScrollAnimation>
            </CatalogoLeft>
            <CatalogoRight>
              <ScrollAnimation animateIn="fadeInRight" delay={100}>
                <SubTitle>Todo Cocinas</SubTitle>
                <Text>Descubre las mejores ideas y</Text>
                <Text>estilos para tu cocina.</Text>
                <ButtonsContainer>
                  <ButtonLink
                      href="Revista_Cocinas.pdf"
                      download="Revista_Cocinas.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                  >
                    DESCARGAR
                  </ButtonLink>

                </ButtonsContainer>
                <ButtonsContainerVer>
                  <ButtonLinkVer
                      href="http://publications.eu.roca.com/42322/1562423"
                      target="_blank"
                      rel="noopener noreferrer"
                  >
                    <img alt=">" src={iconVer} /> Ver
                  </ButtonLinkVer>
                </ButtonsContainerVer>
              </ScrollAnimation>
            </CatalogoRight>
          </ContenedorCatalogo>

        </Column>
      </Row>
    </Wrapper>
  </CatalogoContainer>
)

export default Catalogo
