import React, { useContext, useState, useEffect } from "react"
import styled from "styled-components"
import breakpoints from "../../styles/breakpoints"
import InfoItem from "./infoItem"
import { MapContext } from "./mapContext"
import Arrow from "../../images/drop_arrow.svg"

const InfoItemsWrapp = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;

  > div {
    border-bottom: 1px solid #d8d8d8;

    &:last-child {
      border-bottom: none;
      margin-bottom: -50px;

      @media ${breakpoints.tablet} {
        margin-bottom: 0;
      }
    }

    @media ${breakpoints.tablet} {
      border-bottom: none;
    }
  }
`
const InfoItemsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;

  > div {
    border-bottom: 1px solid #d8d8d8;

    &:last-child {
      border-bottom: none;
      margin-bottom: -50px;

      @media ${breakpoints.tablet} {
        margin-bottom: 0;
      }
    }

    @media ${breakpoints.tablet} {
      border-bottom: none;
    }
  }
`
const InfoItemsSelector = styled.div`
  margin-top: 2rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
const TitleSelector = styled.h5`
  font-size: 20px;
  font-weight: bold;
  line-height: 1.1;
  text-align: center;
  margin: 0 0 20px;
`
const Select = styled.div`
  width: 100%;
  max-width: 30rem;
  select {
    display: flex;
    justify-content: space-between;
    background: #ffffff;
    border: 1px solid rgb(232, 232, 232);
    border-radius: 0;
    width: 100%;
    padding: 0 16px;
    margin-bottom: 15px;
    font-size: 14px;
    font-weight: normal;
    background: url(${Arrow}) no-repeat right;
    outline: none;
    height: 52px;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-position-x: 98%;

    transition: all 0.4s ease;

    &:hover {
      border: 1px solid #d8d8d8;
    }

    &:focus {
      border: 1px solid #d8d8d8;
    }
  }

  option {
    -webkit-appearance: none;
    -moz-appearance: none;
  }
`

const InfoItems = () => {
  const { mapInfoItems, setMapInfoItems } = useContext(MapContext)

  const [options, setOptions] = useState([])

  // cuando cambia el option del select se ejecuta un nuevo filtro
  // sobre mapInfoItems para encontrar items de la provincia selecionada
  const handleChange = e => {
    e.preventDefault()

    const arr = mapInfoItems.map(item => {
      return item.provincia === e.target.value
        ? { ...item, listVisible: true }
        : { ...item, listVisible: false }
    })

    setMapInfoItems([...arr])
  }

  useEffect(() => {
    const setOptionsArray = () => {
      setMapInfoItems(
        mapInfoItems.sort((a, b) =>
          a.localidad > b.localidad ? 1 : b.localidad > a.localidad ? -1 : 0
        )
      )
      const arr = mapInfoItems.map(item => item.provincia)
      const cleanArr = arr
        .filter((item, pos) => arr.indexOf(item) === pos)
        .sort()
      setOptions([...cleanArr])
    }
    setOptionsArray()
  }, [mapInfoItems, setMapInfoItems])

  return (
    <InfoItemsWrapp>
      <InfoItemsSelector>
        <TitleSelector>
          Elige provincia para ver los puntos de venta:
        </TitleSelector>
        <Select>
          <select
            aria-label="Provincia"
            onChange={handleChange}
            onBlur={handleChange}
            defaultValue={"default"}
          >
            <option value="default" disabled={true}>
              Selecciona una provincia
            </option>
            {options.map((item, index) => (
              <option value={item} key={`opt${index}`}>
                {item}
              </option>
            ))}{" "}
          </select>
        </Select>
      </InfoItemsSelector>
      <InfoItemsContainer>
        {mapInfoItems.map(
          (item, index) =>
            item.listVisible && (
              <InfoItem key={`info${item.id}`} itemIndex={index} id={item.id} />
            )
        )}
      </InfoItemsContainer>
    </InfoItemsWrapp>
  )
}

export default InfoItems
